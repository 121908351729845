import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Draggable from "react-draggable";
import { db } from "../../Services/firebase-config"; // Firebase config
import { useAuth } from "../../Contexts/AuthContext";

import {
  ClockIcon,
  RefreshIcon,
  ClipboardCheckIcon,
} from "@heroicons/react/outline";

const FileViewer = () => {
  const { currentUser } = useAuth(); // Get the current user
  const location = useLocation();
  const { fileUrl, mimeType, submissionId } = location.state || {};
  const [previewURL, setPreviewURL] = useState("");
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [iframeKey, setIframeKey] = useState(0);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isTimerOpen, setIsTimerOpen] = useState(false);
  const [minutes, setMinutes] = useState(10);
  const [timeLeft, setTimeLeft] = useState(600);
  const [isCounting, setIsCounting] = useState(false);
  const [sectionScores, setSectionScores] = useState({
    problemDefinition: 0,
    solution: 0,
    novelty: 0,
    objectives: 0,
    methodology: 0,
    outputs: 0,
    relevance: 0,
  });
  const [totalPoints, setTotalPoints] = useState(0);
  const [comments, setComments] = useState("");

    // Fetch existing scores from Firestore for the current user and submission
  const fetchScores = async () => {
    try {
      const docRef = doc(db, "applications", submissionId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const reviewData = data.reviews?.[currentUser.uid]; // Get the current user's review data
        if (reviewData) {
          setSectionScores({
            problemDefinition: reviewData.problemDefinition || 0,
            solution: reviewData.solution || 0,
            novelty: reviewData.novelty || 0,
            objectives: reviewData.objectives || 0,
            methodology: reviewData.methodology || 0,
            outputs: reviewData.outputs || 0,
            relevance: reviewData.relevance || 0,
          });
          setComments(reviewData.comments || "");
        }
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching document: ", error);
    }
  };

  useEffect(() => {
    if (isFormOpen) {
      fetchScores();
    }
  }, [isFormOpen]);

  // Handle score changes
  const handleScoreChange = (e, field) => {
    setSectionScores((prevScores) => ({
      ...prevScores,
      [field]: parseInt(e.target.value) || 0,
    }));
  };

  const handleCommentsChange = (e) => {
    setComments(e.target.value);
  };

  const calculateTotalPoints = () => {
    const total =
      sectionScores.problemDefinition +
      sectionScores.solution +
      sectionScores.novelty +
      sectionScores.objectives +
      sectionScores.methodology +
      sectionScores.outputs +
      sectionScores.relevance;
    setTotalPoints(total);
  };

  useEffect(() => {
    calculateTotalPoints();
  }, [sectionScores]);

  // Submit the updated scores to Firestore
  const handleSubmitScores = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, "applications", submissionId);
      await updateDoc(docRef, {
        [`reviews.${currentUser.uid}`]: {
          problemDefinition: sectionScores.problemDefinition,
          solution: sectionScores.solution,
          novelty: sectionScores.novelty,
          objectives: sectionScores.objectives,
          methodology: sectionScores.methodology,
          outputs: sectionScores.outputs,
          relevance: sectionScores.relevance,
          totalPoints: totalPoints,
          comments: comments,
          reviewedAt: new Date().toISOString(),
        },
      });
      alert("Scores updated successfully!");
      setIsFormOpen(false);
    } catch (error) {
      console.error("Error updating document: ", error);
      alert("Error updating scores.");
    }
  };

 




    // Simulate progress increment while the iframe is loading
    useEffect(() => {
      if (loading) {
        const interval = setInterval(() => {
          setProgress((prev) => (prev < 90 ? prev + 10 : prev)); // Increment progress to 90% max
        }, 500);
        return () => clearInterval(interval);
      }
    }, [loading]);

    const handleIframeLoad = () => {
      setLoading(false);
      setProgress(100); // Set progress to 100% once iframe has loaded
    };
  

  const handleIframeProgress = (event) => {
    const progressPercentage = Math.floor((event.loaded / event.total) * 100);
    setProgress(progressPercentage);
  };

  // Function to toggle the form modal
  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  // Function to set the timer
  const handleSetTimer = (e) => {
    e.preventDefault();
    setTimeLeft(minutes * 60);
    setIsTimerOpen(false);
    setIsCounting(true);
  };

  useEffect(() => {
    if (!fileUrl || !mimeType) {
      alert("Invalid file or file type.");
    }
  }, [fileUrl, mimeType]);

  const renderPreview = () => {
    switch (mimeType) {
      case "application/pdf":
        return (
          <iframe
            key={iframeKey}
            src={fileUrl}
            className="w-full h-full"
            onLoad={handleIframeLoad}
            onProgress={handleIframeProgress}
          />
        );

      case "image/jpeg":
      case "image/png":
      case "image/gif":
        return <img src={fileUrl} alt="File Preview" width="100%" />;

      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document": // For Word files
      case "application/msword": 
      return (
          <iframe
            key={iframeKey}
            src={`https://docs.google.com/gview?url=${encodeURIComponent(
              fileUrl
            )}&embedded=true`}
            className="w-full h-full"
            onLoad={handleIframeLoad}
            onProgress={handleIframeProgress}
          />
        );

      case "application/vnd.ms-excel": // Old Excel format
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": // New Excel format (Google Sheets)
        return (
          <iframe
            src={`https://docs.google.com/gview?url=${encodeURIComponent(
              fileUrl
            )}&embedded=true`}
            className="w-full h-full"
            onLoad={handleIframeLoad}
            onProgress={handleIframeProgress}
          />
        );

      default:
        return <p>Preview not available for this file type.</p>;
    }
  };
  // Function to refresh the iframe
  const handleRefresh = () => {
    setLoading(true);
    setProgress(0);
    setIframeKey((prevKey) => prevKey + 1);
  };

  // Countdown timer logic
  useEffect(() => {
    let timer;
    if (isCounting && timeLeft > 0) {
      timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearTimeout(timer);
  }, [isCounting, timeLeft]);

  return (
    <>
      <div className="flex flex-col h-screen">
        {/* Header section with Score, Refresh, and Timer buttons */}
        <div className="p-6 bg-gray-100 border-b flex justify-between items-center">
          <h2 className="text-xl font-bold">File Preview</h2>

          <div className="flex space-x-4">
            {/* Timer Button */}
            <button
              className="bg-blue-500 text-white px-4 py-2  hover:bg-blue-600 flex items-center"
              onClick={() => setIsTimerOpen(true)}
            >
              <ClockIcon className="h-5 w-5 mr-2" />
              Timer
            </button>

            {/* Refresh Button */}
            <button
              className="bg-custom-blue-1 text-white px-4 py-2  hover:bg-custom-blue-1 flex items-center"
              onClick={handleRefresh}
            >
              <RefreshIcon className="h-5 w-5 mr-2" />
              Refresh
            </button>

            {/* Score Button */}
            <button
              className="bg-custom-green-1 text-white px-4 py-2  hover:bg-custom-green-1 flex items-center"
              onClick={toggleForm}
            >
              <ClipboardCheckIcon className="h-5 w-5 mr-2" />
              Score
            </button>
          </div>
        </div>

        {/* Timer Display */}
        {isCounting && (
          <div className="p-4 bg-yellow-100 text-yellow-800 border-b flex justify-between items-center">
            <span className="font-bold">
              Time Left: {Math.floor(timeLeft / 60)}:
              {("0" + (timeLeft % 60)).slice(-2)} minutes
            </span>
            <button
              className="bg-red-500 text-white px-4 py-2  hover:bg-red-600"
              onClick={() => setIsCounting(false)}
            >
              Stop Timer
            </button>
          </div>
        )}

        {/* Horizontal Loader */}
        {loading && (
          <div className="relative flex justify-center items-center h-full bg-gray-100">
            <div className="relative w-3/4 h-4 bg-gray-300 ">
              <div
                className="absolute top-0 left-0 h-full bg-custom-blue-1 transition-all duration-300"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <div className="ml-4 font-bold text-blue-600 text-lg">{progress}%</div>
          </div>
        )}

        {/* Full Screen Iframe */}
        <div className="flex-grow relative">
          {fileUrl && mimeType && (
            <div className="file-viewer h-screen">{renderPreview()}</div>
          )}
        </div>

       {/* Draggable Floating Score Form */}
       {isFormOpen && (
          <Draggable handle=".draggable-header">
            <div className="fixed z-50 bg-white p-6 shadow-lg w-1/3 top-1/4 left-1/4">
              {/* Draggable Header */}
              <div className="draggable-header cursor-move flex justify-between items-center mb-4">
                <h2 className="text-lg font-bold">Enter Scores</h2>
                <button
                  className="text-gray-500 hover:text-red-600"
                  onClick={toggleForm}
                >
                  &#x2715; {/* Close button */}
                </button>
              </div>

              {/* Score Input Form */}
              <form onSubmit={handleSubmitScores}>
                {/* Problem Definition */}
                <div className="mb-4">
                  <label className="block text-gray-700">Problem Definition (0-15)</label>
                  <input
                    type="number"
                    min="0"
                    max="15"
                    value={sectionScores.problemDefinition}
                    onChange={(e) => {
                      const value = Math.max(0, Math.min(15, e.target.value)); // Ensure value is between 0 and 15
                      handleScoreChange({ target: { value } }, "problemDefinition");
                    }}
                    className="w-full border border-gray-300 p-2 "
                  />
                </div>

                {/* Solution */}
                <div className="mb-4">
                  <label className="block text-gray-700">Solution (0-15)</label>
                  <input
                    type="number"
                    min="0"
                    max="15"
                    value={sectionScores.solution}
                    onChange={(e) => {
                      const value = Math.max(0, Math.min(15, e.target.value)); // Ensure value is between 0 and 15
                      handleScoreChange({ target: { value } }, "solution");
                    }}
                    className="w-full border border-gray-300 p-2 "
                  />
                </div>

                {/* Novelty */}
                <div className="mb-4">
                  <label className="block text-gray-700">Novelty (0-10)</label>
                  <input
                    type="number"
                    min="0"
                    max="10"
                    value={sectionScores.novelty}
                    onChange={(e) => {
                      const value = Math.max(0, Math.min(10, e.target.value)); // Limit value between 0 and 10
                      handleScoreChange({ target: { value } }, "novelty");
                    }}
                    className="w-full border border-gray-300 p-2 "
                  />
                </div>

                {/* Objectives */}
                <div className="mb-4">
                  <label className="block text-gray-700">Objectives (0-5)</label>
                  <input
                    type="number"
                    min="0"
                    max="5"
                    value={sectionScores.objectives}
                    onChange={(e) => {
                      const value = Math.max(0, Math.min(5, e.target.value)); // Limit value between 0 and 5
                      handleScoreChange({ target: { value } }, "objectives");
                    }}
                    className="w-full border border-gray-300 p-2 "
                  />
                </div>

                {/* Methodology */}
                <div className="mb-4">
                  <label className="block text-gray-700">Methodology (0-30)</label>
                  <input
                    type="number"
                    min="0"
                    max="30"
                    value={sectionScores.methodology}
                    onChange={(e) => {
                      const value = Math.max(0, Math.min(30, e.target.value)); // Limit value between 0 and 30
                      handleScoreChange({ target: { value } }, "methodology");
                    }}
                    className="w-full border border-gray-300 p-2 "
                  />
                </div>

                {/* Outputs */}
                <div className="mb-4">
                  <label className="block text-gray-700">Outputs (0-15)</label>
                  <input
                    type="number"
                    min="0"
                    max="15"
                    value={sectionScores.outputs}
                    onChange={(e) => {
                      const value = Math.max(0, Math.min(15, e.target.value)); // Limit value between 0 and 15
                      handleScoreChange({ target: { value } }, "outputs");
                    }}
                    className="w-full border border-gray-300 p-2 "
                  />
                </div>

                {/* Relevance */}
                <div className="mb-4">
                  <label className="block text-gray-700">Relevance (0-10)</label>
                  <input
                    type="number"
                    min="0"
                    max="10"
                    value={sectionScores.relevance}
                    onChange={(e) => {
                      const value = Math.max(0, Math.min(10, e.target.value)); // Limit value between 0 and 10
                      handleScoreChange({ target: { value } }, "relevance");
                    }}





                    className="w-full border border-gray-300 p-2 "
                  />
                </div>

                {/* Total Points Display */}
                <div className="mb-6">
                  <label className="block text-gray-700 font-bold">
                    Total Points: {totalPoints} / 100
                  </label>
                </div>

                {/* Submit Button */}
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="bg-custom-blue-1 text-white px-4 py-2"
                  >
                    Save Scores
                  </button>
                </div>
              </form>
            </div>
          </Draggable>
        )}

        {/* Timer Popup */}
        {isTimerOpen && (
          <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded shadow-lg w-1/3">
              <h2 className="text-lg font-bold mb-4">Set Timer (Minutes)</h2>
              <form onSubmit={handleSetTimer}>
                <input
                  type="number"
                  min="1"
                  value={minutes}
                  onChange={(e) => setMinutes(e.target.value)}
                  className="w-full border border-gray-300 p-2 rounded mb-4"
                  placeholder="Set timer (in minutes)"
                />
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                    onClick={() => setIsTimerOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Start Timer
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>

      {/* Spinner CSS */}
      <style>{`
        .loader {
          border-top-color: #3498db;
          animation: spin 1s linear infinite;
        }
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </>
  );
};

export default FileViewer;
