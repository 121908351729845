import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import { useAuth } from "../../Contexts/AuthContext";
import "jspdf-autotable"; // Import jsPDF AutoTable plugin

const DepartmentReport = () => {
  const location = useLocation();
  const { submissions, bureauName, reviewers } = location.state || {};
  const { currentUser } = useAuth();
  const [statistics, setStatistics] = useState({
    totalApplications: 0,
    averageScore: 0,
    highestScore: 0,
  });

  useEffect(() => {
    if (submissions) {
      const totalApplications = submissions.length;
      const averageScore =
        submissions.reduce(
          (acc, submission) => acc + (submission.averageScore || 0),
          0
        ) / totalApplications;
      const highestScore = Math.max(
        ...submissions.map((submission) => submission.averageScore || 0)
      );
      setStatistics({ totalApplications, averageScore, highestScore });
    }
  }, [submissions]);

  const generatePDF = () => {
    const doc = new jsPDF("p", "mm", "a4");
    let pageNumber = 1; // Track the page number
  
    // Define image and load it properly before generating content
    const headerImage = new Image();
    headerImage.src = "/Images/header.jpg"; // Make sure this path is correct
  
    headerImage.onload = () => {
      // Maintain aspect ratio with width of 190mm
      const imgWidth = 190; // A4 page width in mm
      const imgHeight = (290 / 1060) * imgWidth;
      // Add Header Image with correct dimensions
      doc.addImage(headerImage, "JPEG", 10, 10, imgWidth, imgHeight);
  
      // Add title and header
      doc.setFontSize(18);
      doc.setFont("helvetica");
  
      // Centered text: Bureau Name
      doc.text(`${bureauName}`, 105, imgHeight + 20, { align: "center" });
  
      // Centered text: STI Grants Evaluation Report (new line below bureauName)
      doc.text("STI Grants Evaluation Report", 105, imgHeight + 30, { align: "center" });
  
      // Add space between title and year
      doc.text("2024", 105, imgHeight + 40, { align: "center" });
  
      // Add List of Reviewers (keeping actual names)
      doc.setFontSize(14);
      doc.text("List of Reviewers:", 10, imgHeight + 60); // Adjust the position to add space after title
  
      // Actual reviewer names for the list
      reviewers.forEach((reviewer, index) => {
        doc.text(`${index + 1}. ${reviewer}`, 10, imgHeight + 70 + index * 10); // Spaced out reviewers
      });
  
      const yPositionForSummary = imgHeight + 70 + reviewers.length * 10 + 20;
  
      // Add Evaluation Summary title
      doc.setFontSize(14);
      doc.text("Evaluation Summary:", 10, yPositionForSummary);
      const customBlue = [44, 58, 79]; // RGB value from the image

      // Add Evaluation Summary Table with rounded average scores
      const summaryTableData = submissions.map((submission, index) => [
        index + 1,
        submission.title,
        submission.formattedReviews.length,
        Math.round(submission.averageScore) || "Not Reviewed", // Rounded to the nearest whole number
      ]);
  
      doc.autoTable({
        startY: yPositionForSummary + 10, // Leave space between summary title and table
        head: [["S/N", "Venture Title", "No of Reviewers", "Average Score"]],
        body: summaryTableData,
        headStyles: {
          fillColor: customBlue, // Set the header color to custom-blue-1
          textColor: [255, 255, 255], // White text for the headers
        },
      });
  
      let yPositionAfterSummary = doc.lastAutoTable.finalY + 10; // Adjust padding after the summary
  
      // Add comments table for each submission (disguised reviewer names)
      submissions.forEach((submission, submissionIndex) => {
        if (yPositionAfterSummary + 20 > 270) {
          doc.addPage();
          pageNumber++; // Increment the page number
          yPositionAfterSummary = 20; // Reset position for new page
        }
  
        doc.setFontSize(14);
  
        // Set max width for the title to prevent overflow and wrap text
        const titleText = `${submissionIndex + 1}. ${submission.title}`;
        const maxWidth = 190; // Adjust this width if needed to fit within the page
  
        // Split text to fit within the specified width
        const wrappedTitle = doc.splitTextToSize(titleText, maxWidth);
  
        // Render the wrapped text with space above
        doc.text(wrappedTitle, 10, yPositionAfterSummary);
  
        yPositionAfterSummary += 10 + wrappedTitle.length * 5; // Adjust yPosition after title
  
        // Replace reviewer names with "Reviewer 1", "Reviewer 2", etc. for comments table
        const commentsTableData = submission.formattedReviews.map((review, reviewIndex) => [
          `Reviewer ${reviewIndex + 1}`, // Disguised reviewer name
          review.comment,
        ]);
  
        doc.autoTable({
          startY: yPositionAfterSummary + 5, // Reduced space between title and comments table
          head: [["Reviewer", "Comment"]],
          body: commentsTableData,
          headStyles: {
            fillColor: customBlue, // Set the header color to custom-blue-1
            textColor: [255, 255, 255], // White text for the headers
          },
        });
  
        yPositionAfterSummary = doc.lastAutoTable.finalY + 10;
      });
  
      // Add the footer (user name, date, and page number) on every page
      const totalPages = doc.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        // Footer with user info
        doc.text(
          `Generated by ${currentUser.displayName} on ${new Date().toLocaleDateString()}`,
          10,
          285
        );
        // Page numbers
        doc.text(`Page ${i} of ${totalPages}`, 180, 285); // Align to the right
      }
  
      // Save the PDF
      doc.save(`${bureauName}_Evaluation_Report.pdf`);
    };
  };
  
  
  
  return (
    <div className="department-report-container p-8">
      {/* Generate PDF Button */}
      <div className="mt-6">
        <button
          onClick={generatePDF}
          className="bg-custom-blue-1 text-white px-4 py-2  hover:bg-blue-600"
        >
          Export as PDF
        </button>
      </div>
      {/* Header Section */}
      <div className="header-section">
        <img
          src={`/Images/header.jpg`}
          alt="Department Header"
          className="w-full h-64 object-cover"
        />
      </div>

      {/* Bureau Name, Date, and Year */}
      <div className="text-center mt-6">
        <h1 className="text-4xl font-bold">{bureauName}</h1>
        <h2 className="text-2xl font-bold">STI Grants Evaluation Report</h2>
        <p className="text-2xl">2024</p>
      </div>

      {/* List of Reviewers */}
      <div className="reviewers-section mt-6">
        <h3 className="text-2xl font-bold">List of Reviewers</h3>
        <ol className="list-decimal list-inside">
          {reviewers?.map((reviewer, index) => (
            <li key={index}>{reviewer}</li>
          ))}
        </ol>
      </div>

      {/* Evaluation Summary */}
      <div className="evaluation-summary p-6 mt-6">
        <h3 className="text-2xl font-bold">Evaluation Summary</h3>
        <table className="table-auto w-full mt-4">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2">S/N</th>
              <th className="px-4 py-2">Venture Title</th>
              <th className="px-4 py-2">No of Reviewers</th>
              <th className="px-4 py-2">Average Score</th>
            </tr>
          </thead>
          <tbody>
            {submissions?.map((submission, index) => {
              const reviewCount = submission.formattedReviews.length;
              return (
                <tr
                  key={submission.id}
                  className={index % 2 === 0 ? "bg-gray-100" : ""}
                >
                  <td className="border px-4 py-2">{index + 1}</td>
                  <td className="border px-4 py-2">{submission.title}</td>
                  <td className="border px-4 py-2">{reviewCount}</td>
                  <td className="border px-4 py-2">
                    {submission.averageScore || "Not Reviewed"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Assessment Comments */}
      <div className="assessment-comments p-6 mt-6">
        <h3 className="text-2xl font-bold">Assessment Comments</h3>
        {submissions?.map((submission, index) => (
          <div key={submission.id} className="mt-4">
            {/* Adding the number prefix to the venture title */}
            <h4 className="text-xl font-semibold">
              {index + 1}. {submission.title}
            </h4>
            <table className="table-auto w-full mt-2">
              <thead>
                <tr className="bg-gray-200">
                  <th className="px-4 py-2">Reviewer</th>
                  <th className="px-4 py-2">Comment</th>
                </tr>
              </thead>
              <tbody>
                {submission.formattedReviews.map((review, reviewIndex) => (
                  <tr
                    key={reviewIndex}
                    className={reviewIndex % 2 === 0 ? "bg-gray-100" : ""}
                  >
                    <td className="border px-4 py-2">{review.reviewerName}</td>
                    <td className="border px-4 py-2">{review.comment}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DepartmentReport;
