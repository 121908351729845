import React, { Fragment, useState, useEffect, useRef } from "react";
import AdminTopBar from "./AdminTopBar";
import { useNavigate, useLocation } from "react-router-dom";
import { db } from "../../Services/firebase-config.js";
import { Menu, Transition } from "@headlessui/react";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import {
  FilterIcon,
  SearchIcon,
  DocumentReportIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";

import {
  doc,
  getDocs,
  getDoc,
  collection,
  updateDoc,
  onSnapshot,
  limit,
  query,
  where,
} from "firebase/firestore";
import { useApplicationForm } from "../../Contexts/ApplicationFormContext";
import { useAuth } from "../../Contexts/AuthContext";

const Submissions = () => {
  const [hoveredSubmissionId, setHoveredSubmissionId] = useState(null);
  const { currentUser } = useAuth();
  const location = useLocation();
  const bureau = location.state?.bureau;
  const { userData, submissions, setSubmissions } = useApplicationForm();
  const [selectedSubmissions, setSelectedSubmissions] = useState([]);
  const [fromRank, setFromRank] = useState(""); // Start rank for range selection
  const [toRank, setToRank] = useState(""); // End rank for range selection
  const [approvedCount, setApprovedCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);

  // Initialize selectedBureau to bureau or user's department
  const [selectedBureau, setSelectedBureau] = useState(
    bureau ?? userData?.department ?? ""
  );

  // const [selectedBureau, setSelectedBureau] = useState(bureau);s
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reviewed, setReviewed] = useState(null);
  const initialRender = useRef(true);

  useEffect(() => {
    if (!selectedBureau && userData?.department) {
      setSelectedBureau(userData.department);
    }

    // if (initialRender.current) {
    //   initialRender.current = false;
    //   return;
    // }

    const unsubscribe = fetchFormData();
    return unsubscribe;
  }, [selectedBureau, reviewed]);

  const fetchFormData = () => {
    const baseQuery = [
      where("submitted", "==", true),
      ...(selectedBureau ? [where("valueChain", "==", selectedBureau)] : []),
    ];
    const q = query(collection(db, "applications"), ...baseQuery);

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const submissionsList = await Promise.all(
          querySnapshot.docs.map(async (docSnapshot) => {
            const data = docSnapshot.data();
            let averageScore = "Not Reviewed";

            // Calculate the average score if reviews exist
            if (data.reviews) {
              const reviews = data.reviews;
              const totalPoints = Object.values(reviews).reduce(
                (sum, review) => sum + review.totalPoints,
                0
              );
              averageScore = (
                totalPoints / Object.keys(reviews).length
              ).toFixed(1);
            }

            // Fetch the corresponding user details from the 'users' collection
            const userId = docSnapshot.id; // The ID of the application matches the user's ID
            const userDocRef = doc(db, "users", userId);
            const userDoc = await getDoc(userDocRef);
            const userData = userDoc.exists() ? userDoc.data() : {};

            return {
              id: docSnapshot.id,
              ...data,
              averageScore:
                averageScore === "Not Reviewed" ? -1 : parseFloat(averageScore),
              applicantName: userData.displayName || "Unknown Applicant",
              applicantEmail: userData.email || "No Email",
            };
          })
        );

        // Sort by averageScore in descending order
        submissionsList.sort((a, b) => b.averageScore - a.averageScore);

        setSubmissions(submissionsList);

        // Update the counts for approved and rejected submissions
        const approved = submissionsList.filter(
          (submission) => submission.evaluationPhase1Status === "approved"
        ).length;
        const rejected = submissionsList.filter(
          (submission) => submission.evaluationPhase1Status === "rejected"
        ).length;

        setApprovedCount(approved);
        setRejectedCount(rejected);
      } else {
        setSubmissions([]);
      }
    });

    return unsubscribe;
  };
  const [statusFilter, setStatusFilter] = useState({
    approved: false,
    rejected: false,
  });

  const [filter, setFilter] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDirection, setSortDirection] = useState("ascending");
  const navigate = useNavigate(); // To handle navigation
  const [progress, setProgress] = useState(0); // Tracks completed files
  const [total, setTotal] = useState(0); // Total files to process

  const bureaus = [
    "Pathogen Economy",
    "Mobility",
    "Infrastructure Innovations",
    "Industry 4.0+",
    "Aeronautics and Space",
    "Productivity Acceleration",
    "STI Support Services",
  ];

  const handleUpdate = async (id, key, value) => {
    await updateDoc(doc(db, "applications", id), {
      [key]: value,
    });
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle sorting by innovation title
  const handleSort = () => {
    const sortedSubmissions = [...submissions].sort((a, b) => {
      if (sortDirection === "ascending") {
        return a.title.localeCompare(b.title);
      } else {
        return b.title.localeCompare(a.title);
      }
    });
    setSortDirection(
      sortDirection === "ascending" ? "descending" : "ascending"
    );
    setSubmissions(sortedSubmissions);
  };

  const filteredSubmissions = submissions?.filter((submission) => {
    const reviews = submission.reviews || {};
    const hasCurrentUserReviewed =
      reviews?.[currentUser?.uid]?.reviewed === true;

    // Filter by the selected review status
    if (reviewed === "reviewedByMe" && !hasCurrentUserReviewed) return false; // Only show submissions reviewed by the current user
    if (reviewed === "notReviewedByMe" && hasCurrentUserReviewed) return false; // Only show submissions not reviewed by the current user

    // Continue filtering by general review status
    if (reviewed === true) {
      const isReviewed = Object.values(reviews).some(
        (review) => review.reviewed === true
      );
      if (!isReviewed) return false; // Filter out unreviewed
    }
    if (reviewed === false) {
      const isReviewed = Object.values(reviews).some(
        (review) => review.reviewed === true
      );
      if (isReviewed) return false; // Filter out reviewed
    }

    // Filter by status
    if (
      statusFilter.approved &&
      submission.evaluationPhase1Status !== "approved"
    ) {
      return false;
    }
    if (
      statusFilter.rejected &&
      submission.evaluationPhase1Status !== "rejected"
    ) {
      return false;
    }
    // Continue filtering by search term if applicable
    if (
      searchTerm &&
      !submission.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
      return false;

    return true; // Return the submission if it passes all filters
  });

  const handleRowClick = (submission) => {
    navigate(`/submissions/${submission.id}`, { state: { submission } });
  };

  const toSentenceCase = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const openModal = (bureau) => {
    setSelectedBureau(bureau);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const handleGenerateReport = () => {
    const submissionsForReport = filteredSubmissions.map((submission) => {
      // Extract the necessary data for each submission
      const formattedReviews = Object.entries(submission.reviews || {}).map(
        ([reviewId, review]) => ({
          reviewerName: review.reviewName || "Unknown Reviewer",
          comment: review.comments || "No Comments", // 'comments' field exists in the reviews map
        })
      );

      return {
        ...submission,
        formattedReviews, // Add formatted reviews for each submission
      };
    });

    // Navigate to the report page, passing the relevant data
    navigate("/department-report", {
      state: {
        submissions: submissionsForReport,
        bureauName: selectedBureau,
        reviewers: [
          ...new Set(
            filteredSubmissions.flatMap((sub) =>
              Object.values(sub.reviews || {}).map(
                (review) => review.reviewName || "Unknown Reviewer"
              )
            )
          ),
        ], // Get unique reviewers
      },
    });
  };

  const downloadCSV = () => {
    const csvRows = [];

    // Add CSV headers
    csvRows.push(
      '"Rank","Innovation Title","Bureau","Applicant Name","Applicant Email"'
    );

    // Loop through submissions and format as CSV rows
    submissions.forEach((submission, index) => {
      const rank = index + 1; // Rank based on the index
      const title = submission.title
        ? `"${submission.title.replace(/"/g, '""')}"`
        : '"Untitled Innovation"';
      const bureau = submission.valueChain
        ? `"${submission.valueChain.replace(/"/g, '""')}"`
        : '"N/A"';
      const applicantName = submission.applicantName
        ? `"${submission.applicantName.replace(/"/g, '""')}"`
        : '"Unknown Applicant"';
      const applicantEmail = submission.applicantEmail
        ? `"${submission.applicantEmail.replace(/"/g, '""')}"`
        : '"No Email"';

      // Push the formatted row into csvRows
      csvRows.push(
        `${rank},${title},${bureau},${applicantName},${applicantEmail}`
      );
    });

    // Create a CSV file content
    const csvContent = `data:text/csv;charset=utf-8,${csvRows.join("\n")}`;
    const encodedUri = encodeURI(csvContent);

    // Trigger the CSV download
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "submissions.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleBackup = async () => {
    const zip = new JSZip();

    // Filter submissions based on the applied filters
    const filteredBackupSubmissions = filteredSubmissions.filter(
      (submission) => {
        if (
          statusFilter.approved &&
          submission.evaluationPhase1Status !== "approved"
        ) {
          return false;
        }
        if (
          statusFilter.rejected &&
          submission.evaluationPhase1Status !== "rejected"
        ) {
          return false;
        }
        return true;
      }
    );

    // Set total for progress tracking
    setTotal(filteredBackupSubmissions.length);

    // Create a single main folder for all applications
    const mainFolder = zip.folder(selectedBureau || "All_Submissions");

    let completed = 0;

    for (const submission of filteredBackupSubmissions) {
      // Create a folder named after the project title inside the main folder
      const projectFolder = mainFolder.folder(
        submission.title || `Application_${submission.id}`
      );

      // Attachments to download for each application
      const attachmentKeys = [
        "applicationFile",
        "budgetFile",
        "teamCVs",
        "nationalID",
        "registrationCertificate",
        "resolutionLetter",
      ];

      for (const key of attachmentKeys) {
        const attachmentUrl = submission[key];

        if (attachmentUrl) {
          try {
            // Fetch the file
            const response = await fetch(attachmentUrl);
            const blob = await response.blob();

            // Extract file name with extension
            const fileName = getFileNameWithExtension(attachmentUrl);

            // Add it to the project folder
            projectFolder.file(fileName, blob);
          } catch (error) {
            console.error(
              `Error fetching ${key} for submission ${submission.id}:`,
              error
            );
          }
        }
      }

      // Update progress tracking
      completed += 1;
      setProgress(completed);
    }

    // Generate and download the zip file
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${selectedBureau || "All_Submissions"}.zip`);
      setProgress(0); // Reset progress after completion
      setTotal(0);
    });
  };

  const getFileNameWithExtension = (url) => {
    // Use URL constructor to isolate the file path
    const urlObj = new URL(url);
    const pathParts = urlObj.pathname.split("/"); // Extract the file path parts
    const fileName = pathParts[pathParts.length - 1]; // Get the last part of the path
    return decodeURIComponent(fileName); // Decode any encoded characters
  };

  const handleCheckboxChange = (submissionId) => {
    setSelectedSubmissions((prevSelected) => {
      if (prevSelected.includes(submissionId)) {
        return prevSelected.filter((id) => id !== submissionId);
      } else {
        return [...prevSelected, submissionId];
      }
    });
  };

  const handleRangeSelection = () => {
    // Convert fromRank and toRank to integers
    const start = parseInt(fromRank, 10);
    const end = parseInt(toRank, 10);

    // Validate the range values
    if (isNaN(start) || isNaN(end) || start <= 0 || end <= 0 || start > end) {
      alert("Please enter a valid range.");
      return;
    }

    // Select submissions within the provided rank range
    const rangeSelectedSubmissions = filteredSubmissions
      .slice(start - 1, end) // Adjust indexing to start from 0
      .map((submission) => submission.id);

    // Update selected submissions state
    setSelectedSubmissions(rangeSelectedSubmissions);
  };

  const updateApplicationStatus = async (status) => {
    // Loop through each selected submission
    for (const submissionId of selectedSubmissions) {
      try {
        await updateDoc(doc(db, "applications", submissionId), {
          evaluationPhase1Status: status, // Set status to either "approved" or "rejected"
        });
      } catch (error) {
        console.error(`Error updating application ${submissionId}:`, error);
      }
    }

    // Reset the selected submissions after updating
    setSelectedSubmissions([]);
    alert(
      `Successfully updated the status to "${status}" for the selected submissions.`
    );
  };

  return (
    <>
      <AdminTopBar title="Submissions" />

      <div className="container mx-auto p-6 space-y-6">
        <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
          {/* Filter Icon Button */}
          <Menu as="div" className="relative  mr-2 inline-block text-left">
            <div>
              <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm  px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                <FilterIcon className="w-5 h-5" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <form className="px-4 py-2">
                    {/* Filter by Review Status */}

                    {/* Filter by Status */}
                    <fieldset className="mt-4">
                      <legend className="text-sm font-semibold text-gray-900 mb-2">
                        Filter by Status
                      </legend>
                      <div className="flex items-center mb-1">
                        <input
                          id="filter-approved"
                          type="checkbox"
                          checked={statusFilter.approved}
                          onChange={(e) =>
                            setStatusFilter((prev) => ({
                              ...prev,
                              approved: e.target.checked,
                            }))
                          }
                          className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                        />
                        <label
                          htmlFor="filter-approved"
                          className="ml-3 text-sm text-gray-700"
                        >
                          Approved
                        </label>
                      </div>
                      <div className="flex items-center mb-1">
                        <input
                          id="filter-rejected"
                          type="checkbox"
                          checked={statusFilter.rejected}
                          onChange={(e) =>
                            setStatusFilter((prev) => ({
                              ...prev,
                              rejected: e.target.checked,
                            }))
                          }
                          className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                        />
                        <label
                          htmlFor="filter-rejected"
                          className="ml-3 text-sm text-gray-700"
                        >
                          Rejected
                        </label>
                      </div>
                    </fieldset>

                    <fieldset>
                      <legend className="text-sm font-semibold text-gray-900 mb-2">
                        Filter by Review Status
                      </legend>
                      {[
                        { label: "All Submissions", value: "" },
                        { label: "Reviewed", value: "true" },
                        { label: "Not Reviewed", value: "false" },
                        { label: "Reviewed by Me", value: "reviewedByMe" },
                        {
                          label: "Not Reviewed by Me",
                          value: "notReviewedByMe",
                        },
                      ].map((option) => (
                        <div
                          key={option.value}
                          className="flex items-center mb-1"
                        >
                          <input
                            id={`filter-${option.value}`}
                            type="checkbox"
                            value={option.value}
                            checked={reviewed === option.value}
                            onChange={(e) =>
                              setReviewed(
                                e.target.checked ? option.value : null
                              )
                            }
                            className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                          />
                          <label
                            htmlFor={`filter-${option.value}`}
                            className="ml-3 text-sm text-gray-700"
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </fieldset>

                    {/* Bureau Filter */}
                    {userData?.role === "Administrator" && (
                      <fieldset className="mt-4">
                        <legend className="text-sm font-semibold text-gray-900 mb-2">
                          Filter by Bureau
                        </legend>
                        {bureaus.map((bureau, index) => (
                          <div key={index} className="flex items-center mb-1">
                            <input
                              id={`bureau-${index}`}
                              type="checkbox"
                              value={bureau}
                              checked={selectedBureau === bureau}
                              onChange={(e) =>
                                setSelectedBureau(
                                  e.target.checked ? bureau : ""
                                )
                              }
                              className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                            />
                            <label
                              htmlFor={`bureau-${index}`}
                              className="ml-3 text-sm text-gray-700"
                            >
                              {bureau}
                            </label>
                          </div>
                        ))}
                      </fieldset>
                    )}
                  </form>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <div className="flex items-center border border-gray-300 p-2 transition-all focus-within:ring-2 focus-within:ring-blue-500">
            <SearchIcon className="w-5 h-5 text-gray-500 mr-2" />
            <input
              type="text"
              className="w-40 md:w-64 transition-all focus:outline-none"
              placeholder="Search submissions?..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>

          {/* Right-aligned Filters and Button */}
          <div className="flex items-center ml-auto space-x-4">
            {/* Report Button with Icon */}
            {/* Conditionally render the Report button if the user is either an Administrator or a Team Lead */}
            {(userData.role === "Administrator" ||
              userData.position === "Team Lead") && (
              <button
                className="flex items-center bg-custom-blue-1 text-white p-2 hover:bg-blue-600 transition duration-300 ease-in-out"
                onClick={handleGenerateReport}
                disabled={!selectedBureau} // Disable button if no bureau is selected
              >
                <DocumentReportIcon className="w-5 h-5 mr-2" />
                <span>Generate Report</span>
              </button>
            )}

            {/* Export Dropdown Button for Administrator */}
            {userData?.role === "Administrator" && (
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 relative">
                    {/* Show progress percentage if downloading */}
                    {total > 0 ? (
                      <>
                        {((progress / total) * 100).toFixed(0)}% Export
                        <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1 text-blue-500 animate-pulse" />
                      </>
                    ) : (
                      <>
                        Export
                        <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1 text-gray-400" />
                      </>
                    )}
                  </Menu.Button>
                </div>

                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={downloadCSV}
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } group flex rounded-md items-center w-full px-4 py-2 text-sm`}
                        >
                          Export CSV
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={handleBackup}
                          disabled={total > 0} // Disable button while backup is in progress
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } group flex flex-col rounded-md items-center w-full px-4 py-2 text-sm`}
                        >
                          <span>Backup Submissions</span>

                          {/* Progress Display */}
                          {total > 0 && (
                            <div className="w-full mt-2">
                              <p className="text-xs text-gray-600 mb-1">
                                {progress}/{total} Files Completed
                              </p>
                              <div className="w-full bg-gray-200 rounded-full h-2">
                                <div
                                  className="bg-blue-500 h-2 rounded-full"
                                  style={{
                                    width: `${(progress / total) * 100}%`,
                                  }}
                                ></div>
                              </div>
                            </div>
                          )}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Menu>
            )}
          </div>
          {userData?.role === "Administrator" && (
            <Menu as="div" className="relative ml-2 inline-block text-left">
              <div>
                <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                  Actions
                  <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1 text-gray-400" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1 px-4">
                    <div className="mb-4">
                      <label
                        htmlFor="fromRank"
                        className="block text-sm font-medium text-gray-700"
                      >
                        From Rank
                      </label>
                      <input
                        type="number"
                        id="fromRank"
                        value={fromRank}
                        onChange={(e) => setFromRank(e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:outline-none"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="toRank"
                        className="block text-sm font-medium text-gray-700"
                      >
                        To Rank
                      </label>
                      <input
                        type="number"
                        id="toRank"
                        value={toRank}
                        onChange={(e) => setToRank(e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:outline-none"
                      />
                    </div>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => handleRangeSelection()}
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } group flex rounded-md items-center w-full px-4 py-2 text-sm`}
                        >
                          Select Range
                        </button>
                      )}
                    </Menu.Item>
                    <div className="py-1 px-4">
                      <p className="text-sm font-medium text-gray-700">
                        Selected Submissions: {selectedSubmissions.length}
                      </p>
                      <p className="text-sm font-medium text-gray-700">
                        Approved: {approvedCount}
                      </p>
                      <p className="text-sm font-medium text-gray-700 mt-1">
                        Rejected: {rejectedCount}
                      </p>
                    </div>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => updateApplicationStatus("approved")}
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } group flex rounded-md items-center w-full px-4 py-2 text-sm`}
                        >
                          Approve Selected
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => updateApplicationStatus("rejected")}
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } group flex rounded-md items-center w-full px-4 py-2 text-sm`}
                        >
                          Reject Selected
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          )}
        </div>

        <table className="min-w-full bg-white border border-gray-300 table-auto">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b text-left">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedSubmissions(
                        filteredSubmissions.map((s) => s.id)
                      );
                    } else {
                      setSelectedSubmissions([]);
                    }
                  }}
                />
              </th>
              <th className="py-2 px-4 border-b text-left">No</th>
              <th
                className="py-2 px-4 border-b text-left cursor-pointer"
                onClick={handleSort}
              >
                Innovation Title {sortDirection === "ascending" ? "↑" : "↓"}
              </th>
              <th className="py-2 px-4 border-b text-left">Reviewers</th>
              <th className="py-2 px-4 border-b text-left">Score</th>
              {userData?.role === "Administrator" && (
                <th className="py-2 px-4 border-b text-left">Action</th>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredSubmissions?.map((submission, index) => (
              <tr
                key={index}
                onClick={() => handleRowClick(submission)}
                onMouseEnter={() => setHoveredSubmissionId(submission.id)}
                onMouseLeave={() => setHoveredSubmissionId(null)}
                className={`
          cursor-pointer hover:bg-gray-100
          ${
            submission.evaluationPhase1Status === "approved"
              ? "bg-green-50"
              : ""
          }
          ${submission.evaluationPhase1Status === "rejected" ? "bg-red-50" : ""}
          ${
            submission.reviews && submission.reviews[currentUser.uid]
              ? "border-l-4 border-green-200"
              : ""
          }
        `}
              >
                <td className="py-2 px-4 border-b">
                  <input
                    type="checkbox"
                    checked={selectedSubmissions.includes(submission.id)}
                    onChange={() => handleCheckboxChange(submission.id)}
                    onClick={(e) => e.stopPropagation()}
                  />
                </td>
                <td className="py-2 px-4 border-b">{index + 1}</td>
                <td className="py-2 px-4 border-b">{submission.title}</td>

                <td className="py-2 px-4 border-b relative">
                  {Object.keys(submission.reviews ?? {}).length}

                  {/* Show reviewer details on hover */}
                  {hoveredSubmissionId === submission.id && (
                    <div className="absolute bg-white border border-gray-300 shadow-lg p-2  left-0 top-full mt-2 w-64 z-10">
                      <h3 className="font-bold mb-1">Reviewers:</h3>
                      <ul className="text-sm text-gray-700">
                        {Object.keys(submission.reviews ?? {}).map(
                          (reviewId) => {
                            const review = submission.reviews?.[reviewId] || {}; // Fallback to an empty object if review is null
                            const isCurrentUser = reviewId === currentUser?.uid;
                            return (
                              <li
                                key={reviewId}
                                className={`p-2 ${
                                  isCurrentUser
                                    ? "bg-blue-100 font-semibold"
                                    : ""
                                }`}
                              >
                                {/* Safely display reviewer's name or fallback */}
                                {review?.reviewName || "Unknown Reviewer"}
                                {/* Safely display score only if the current user is the reviewer and score exists */}
                                {isCurrentUser &&
                                  review?.totalPoints !== undefined && (
                                    <span className="ml-2 text-blue-600">
                                      (Your score: {review.totalPoints})
                                    </span>
                                  )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )}
                </td>

                <td className="py-2 px-4 border-b">
                  {submission.averageScore !== -1 ? (
                    submission.averageScore
                  ) : (
                    <span className="text-red-600">Not Reviewed</span>
                  )}
                </td>

                {userData?.role === "Administrator" && (
                  <td className="py-2 px-4 border-b">
                    <select
                      className="border border-gray-300 p-2 "
                      value={submission.valueChain ?? ""}
                      onChange={(e) =>
                        handleUpdate(
                          submission.id,
                          "valueChain",
                          e.target.value
                        )
                      }
                      onClick={(e) => e.stopPropagation()}
                    >
                      <option value="" disabled>
                        Forward to Bureau
                      </option>
                      {bureaus.map((bureau, i) => (
                        <option key={i} value={bureau}>
                          {bureau}
                        </option>
                      ))}
                    </select>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default Submissions;
