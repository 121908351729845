import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AdminTopBar from "./AdminTopBar";
import { DownloadIcon, ArrowsExpandIcon } from "@heroicons/react/outline";
import { FaFileWord, FaFileExcel } from "react-icons/fa";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { useApplicationForm } from "../../Contexts/ApplicationFormContext";

const DashboardContent = () => {
  const { userData } = useApplicationForm();
  const [timeLeft, setTimeLeft] = useState("");
  const deadline = new Date("2024-09-18T23:59:00"); // Exact deadline: 18th September 2024, 11:59 PM EAT

  // Timer logic for countdown
  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = deadline - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTimeLeft(
          `${days} Days ${hours} Hrs ${minutes} Mins ${seconds} Secs`
        );
      } else {
        setTimeLeft("Submission closed");
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000); // Update every second
    return () => clearInterval(timer);
  }, []);

  const openInNewTab = () => {
    window.open("/files/STI-CALL-FOR-APPLICATIONS.pdf", "_blank");
  };

  const templates = [
    {
      name: "Proposal Template (Word)",
      description: "Download the Word template for submitting your proposal.",
      fileUrl:
        "/templates/2024-Funding-Application-Template-STI-OP-All-phases.docx",
      type: "Word",
      icon: <FaFileWord className="w-10 h-10 text-blue-600" />,
    },
    {
      name: "Phase 1 Budget Template (Excel)",
      description:
        "If your innovation is in Phase 1, Download this Excel template for submitting your budget.",
      fileUrl: "/templates/Phase-1-Budget-Template-ST-OP.xls",
      type: "Excel",
      icon: <FaFileExcel className="w-10 h-10 text-green-600" />,
    },
    {
      name: "Phase 2, 3, or 4 and 1 products Budget Template (Excel)",
      description:
        "If your innovation is in phase 2, 3, or 4, and has 1 product, Download this Excel template for submitting your budget.",
      fileUrl: "/templates/Phase-2-1-product-final-Business-budget-STI-OP.xlsx",
      type: "Excel",
      icon: <FaFileExcel className="w-10 h-10 text-green-600" />,
    },
    {
      name: "Phase 2, 3, or 4 and 2 products Budget Template (Excel)",
      description:
        "If your innovation is in phase 2, 3, or 4, and has 2 products, Download this Excel template for submitting your budget.",
      fileUrl: "/templates/Phase-2-2-products-final-Business-budget-STI-OP.xlsx",
      type: "Excel",
      icon: <FaFileExcel className="w-10 h-10 text-green-600" />,
    },
    {
      name: "Phase 2, 3, or 4 and 3 products Budget Template (Excel)",
      description:
        "If your innovation is in phase 2, 3, or 4, and has 3 products, Download this Excel template for submitting your budget.",
      fileUrl: "/templates/Phase-2-3-products-final-Business-budget-STI-OP.xlsx",
      type: "Excel",
      icon: <FaFileExcel className="w-10 h-10 text-green-600" />,
    },
    {
      name: "Phase 2, 3, or 4 and 4 products Budget Template (Excel)",
      description:
        "If your innovation is in phase 2, 3, or 4, and has 4 products, Download this Excel template for for submitting your budget.",
      fileUrl: "/templates/Phase-2-4-products-final-Business-budget-STI-OP.xlsx",
      type: "Excel",
      icon: <FaFileExcel className="w-10 h-10 text-green-600" />,
    },
    {
      name: "Phase 2, 3, or 4 and 5 products Budget Template (Excel)",
      description:
        "If your innovation is in phase 2, 3, or 4, and has 5 products, Download this Excel template for for submitting your budget.",
      fileUrl: "/templates/Phase-2-5-product-final-Business-budget-STI-OP.xlsx",
      type: "Excel",
      icon: <FaFileExcel className="w-10 h-10 text-green-600" />,
    },
  ];

  return (
    <>
      <AdminTopBar title="Dashboard" />
      <div className="p-5 w-full">
        <div className="bg-green-100 text-green-800 p-4 mb-5 flex flex-col md:flex-row justify-between items-center">
          <div className="mb-2 md:mb-0">
            <span className="font-semibold">Submission Status:</span>{" "}
            {/* {userData?.applicationSubmitted ? "" : "Not"} Submitted */}
            Evaluation Results available
          </div>
          <div className="mb-2 md:mb-0">
            <span className="font-semibold">Time Left: {
              deadline > new Date() ? timeLeft : "Submission Closed"
              }</span>
          </div>

          {/* "Submit Now" Link with Tailwind Animation
          {!userData?.applicationSubmitted && (
            <Link
              to="/application-form"
              className="bg-custom-blue-1 text-white px-4 py-2 hover:bg-blue-600 transition duration-300 ease-in-out animate-bounce"
            >
              Apply Now
            </Link>
          )} */}
        </div>

        <div className="mb-8">
          <h2 className="text-xl font-bold mb-4">Scope</h2>
          <p className="text-gray-700">
            The Science, Technology and Innovation Secretariat – Office of the
            President (STI-OP) has a Goal of Growing the Contribution of
            Knowledge-Based Goods and Services to the National Economy by
            Increasing Domestic Productivity, Import Substitution and Export of
            Products resulting from Science, Technology and Innovation. Toward
            this goal, STI-OP calls for applications from individuals/
            institutions targeting Grant awards for R&D and innovations
            (process, products, technology or business models) in the Thematic
            Areas listed in the call.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-xl font-bold mb-4">How to Apply</h2>
          <ol className="list-decimal pl-6 space-y-4 text-gray-700">
            <li>
              <strong>Download the grant application templates to use.</strong>
              <p>
                Ensure you download the latest versions of the required Word and
                Excel templates for your submission.
              </p>
            </li>

            <li>
              <strong>
                Fill out the templates with the information regarding your
                innovation.
              </strong>
              <p>
                Use the video provided to guide you on how to correctly fill out
                each section of the templates.
              </p>
              <a
                href="https://youtu.be/kk6Joupl12Y"
                className="text-blue-500 hover:text-blue-700 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Watch Tutorial Video 1 (Word Application Template)
              </a>
              <br></br>
              <a
                href="https://youtu.be/YuCw9d73vDI"
                className="text-blue-500 hover:text-blue-700 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Watch Tutorial Video 2 (Excel Budget Template)
              </a>
            </li>

            <li>
              <strong>
                Submit the completed templates through this platform.
              </strong>
              <p>
                Once you've filled out the templates, return to this platform
                and submit them using the application form provided.
              </p>
            </li>

            <li>
              <strong>
                Wait for a notification message about the status of your
                application.
              </strong>
              <p>
                After submission, you will receive notifications on the platform
                about the status of your application.
              </p>
            </li>

            <li className="text-red-600">
              <strong>Note:</strong> Once you have submitted the form, you will
              no longer be able to edit it. Additionally, after the deadline
              passes (Application Deadline September 18th, 2024, 11:59pm EAT),
              you will no longer be able to submit any application.
            </li>
          </ol>
        </div>

        <div className="mb-8">
          <h2 className="text-xl font-bold mb-4">Idea to Market Journey</h2>

          {/* Image Section */}
          <div className="mb-4 flex justify-center">
            <img
              src="/Images/Idea-to-Market.jpg" // Path to your image
              alt="Idea to Market"
              className="w-full sm:w-3/4 md:w-full lg:w-1/2 rounded-lg shadow-lg" // Responsive width
            />
          </div>

          {/* Description Section */}
          {/* <p className="text-gray-700 mb-4 text-center">
            To learn more about the journey from idea to market, watch the video
            in the link attached below.
          </p> */}

          {/* Video Link */}
          {/* <div className="text-center">
            <a
              href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
              className="text-blue-500 hover:text-blue-700 underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Watch Idea to Market Journey Video
            </a>
          </div> */}
        </div>

        <div className="mb-8">
          <h2 className="text-xl font-bold mb-4">Templates</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {templates.map((template, index) => (
              <div
                key={index}
                className="bg-white shadow p-4 rounded-lg flex flex-col items-center justify-between"
              >
                {/* Icon and Template Info */}
                <div className="flex flex-col items-center">
                  {template.icon}
                  <h3 className="font-bold text-lg mt-2">{template.name}</h3>
                  <p className="text-sm text-gray-600 text-center">
                    {template.description}
                  </p>
                </div>

                {/* Download Section */}
                <a
                  href={template.fileUrl}
                  download
                  className="text-blue-500 hover:text-blue-700 mt-4 flex flex-col items-center"
                >
                  <DownloadIcon className="w-8 h-8 mb-1" />
                  <span>Download</span>
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-xl font-bold mb-4">Eligibility</h2>
          <ul className="list-disc pl-6 space-y-2 text-gray-700">
            <li>
              <strong>
                The call is open to Ugandan individuals and institutions
                (industries, research institutions, Government MDAs, etc).
              </strong>
            </li>
            <li>
              <strong>
                A person must not be a lead applicant on more than one
                application.
              </strong>
            </li>
            <li>
              <strong>Staff of STI-OP are not eligible to apply.</strong>
            </li>
          </ul>
        </div>

        <div className="mb-8">
          {/* Header and Button in the Same Row and Centered */}
          <div className="flex items-center justify-between w-full mb-4">
            <h2 className="text-xl font-bold">Applications Call Preview</h2>
            <button
              onClick={openInNewTab} // Open PDF in new tab
              className="bg-blue-500 text-white px-4 py-2  flex items-center hover:bg-blue-600"
            >
              <ArrowsExpandIcon className="w-5 h-5 mr-2" />
              Expand in New Tab
            </button>
          </div>

          {/* Responsive PDF Preview */}
          <div className="pdf-preview-container border p-4  overflow-y-auto w-full max-w-4xl mx-auto">
            {/* Preview of PDF */}
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
            >
              <Viewer fileUrl="/files/STI-CALL-FOR-APPLICATIONS.pdf" />
            </Worker>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardContent;
