import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import { signOut } from "firebase/auth"; // Import signOut function
import { auth } from "../../Services/firebase-config";
import { useApplicationForm } from "../../Contexts/ApplicationFormContext";

import {
  HomeIcon,
  UsersIcon,
  ClipboardListIcon,
  ShieldCheckIcon,
  InboxInIcon,
  ChartSquareBarIcon,
  BellIcon,
  DocumentTextIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";

const SideNav = ({ children }) => {
  const { userData } = useApplicationForm();
  const [isCollapsed, setIsCollapsed] = useState(false); // State for collapsing
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out from Firebase
      navigate("/"); // Redirect to the homepage after sign out
    } catch (error) {
      console.error("Logout failed", error);
      // Handle errors here, such as displaying a notification
    }
  };

  const navItems = [
    { to: "/adminsdashboard", label: "Admin Dashboard", icon: ChartSquareBarIcon, roles: [ "Administrator"] },
    { to: "/users", label: "Users & Permissions", icon: ShieldCheckIcon, roles: ["Administrator"] },
    { to: "/review", label: "Review Dashboard", icon: ClipboardListIcon, roles: [ "Administrator", "Reviewer"] },
    { to: "/inbox", label: "Inbox", icon: InboxInIcon, roles: [ "Administrator"] },
    { to: "/submissions", label: "Submissions", icon: InboxInIcon, roles: [ "Administrator", "Reviewer"] },
    { to: "/", label: "Home", icon: HomeIcon, roles: ["Applicant", "Administrator", "Reviewer"] },
    { to: "/application-form", label: "Review Comments", icon: DocumentTextIcon, roles: ["Applicant", "Administrator"] },
    { to: "/notifications", label: "Notifications", icon: BellIcon, roles: ["Applicant", "Administrator", "Reviewer"] },
    { to: "/support", label: "Support", icon: InformationCircleIcon, roles: ["Applicant", "Administrator"] },
  ];
  

  return (
    <>
      {/* Side Navigation for Desktop */}
      <div className="flex h-screen">
        {/* Side Navigation for Desktop */}
        <div className="hidden md:flex w-64 bg-custom-blue-1 flex-col justify-between">
          <div>
            <div className="flex items-center justify-center space-x-4 pt-6 pb-8 pl-8">
              <img src="/Images/STIlogo.svg" alt="STI Logo" className="h-12" />
              <h1 className="text-white text-lg font-bold">
                STI GRANT APPLICATION
              </h1>
            </div>

            {/* Separator */}
            <hr className="border-t border-gray-200" />
            <ul className="flex flex-col mt-5 space-y-2">
              {navItems.map(
                ({ to, label, icon: Icon, roles }) =>
                  roles.includes(userData?.role ?? 'Applicant') && (
                    <li key={to}>
                      <NavLink
                        to={to}
                        end={to === "/"}
                        state={{ bureau: userData?.department } }
                        className={({ isActive }) =>
                          `flex items-center space-x-2 p-2 transition-colors ${
                            isActive
                              ? "bg-custom-green-1 text-custom-blue-1"
                              : "hover:bg-custom-green-1 text-white"
                          }`
                        }
                      >
                        <Icon className="w-6 h-6" />
                        <span>{label}</span>
                      </NavLink>
                    </li>
                  )
              )}
            </ul>
          </div>
          {/* Logout Button */}
          <div className="p-4 w-full flex justify-center items-end">
            <button
              onClick={handleLogout}
              className="flex items-center justify-center px-4 py-2 bg-transparent text-white  hover:bg-custom-green-1 "
            >
              {/* Replace the arrow icon with your logout icon */}
              <img
                src="/Images/icons/logout.svg"
                alt="logout"
                className="h-6 w-6 mr-2 "
              />
              LOG OUT
            </button>
          </div>
        </div>
        {/* Main Content */}
        <div className="flex-1 overflow-auto">{children}</div>

        {/* Bottom Navigation for Mobile */}
        <div className="fixed inset-x-0 bottom-0 bg-custom-blue-1 text-white flex justify-around py-3 md:hidden">
          {navItems.map(
                ({ to, label, icon: Icon, roles }) =>
                  roles.includes(userData?.role ?? 'Applicant') && (
            <NavLink
              key={to}
              to={to}
              end={to === "/"}
              className={({ isActive }) =>
                `flex flex-col items-center ${
                  isActive ? "text-custom-green-1" : "text-white"
                }`
              }
            >
              <Icon className="w-6 h-6" />
              <span className="text-xs">{label}</span>
            </NavLink>
          ))}
        </div>
      </div>
    </>
  );
};

export default SideNav;