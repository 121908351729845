import React, { useState, useEffect } from "react";
import AdminTopBar from "./AdminTopBar";
import {getDoc, doc, collection, updateDoc, onSnapshot, limit, query, where, orderBy } from "firebase/firestore";
import { db } from "../../Services/firebase-config.js";
import { SearchIcon } from "@heroicons/react/outline";
import { getAuth } from "firebase/auth";
import Papa from "papaparse";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [roleFilter, setRoleFilter] = useState("");
  const [departmentFilter, setDepartmentFilter] = useState("");


    // Get the authenticated user's email
    const auth = getAuth();

    useEffect(() => {
      // Step 1: Query all submitted applications and update roles in users collection
      const updateApplicantsRole = async () => {
        const q = query(collection(db, "applications"), where("submitted", "==", true));
  
        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
          querySnapshot.forEach(async (docSnapshot) => {
            const userId = docSnapshot.id; // The document ID is the userId
            const userRef = doc(db, "users", userId);
  
            // Check if the user document exists
            const userDoc = await getDoc(userRef);
  
            if (userDoc.exists()) {
              const userData = userDoc.data();
  
              // Update the role if the document exists
              await updateDoc(userRef, { role: "Applicant" }, { merge: true });
  
              // Check if displayName is missing
              if (!userData.displayName) {
                try {
                  // Get user info from Firebase Auth
                  const userAuth = await auth.getUser(userId);
                  if (userAuth.displayName) {
                    // Update Firestore with the displayName from Firebase Auth
                    await updateDoc(userRef, { displayName: userAuth.displayName }, { merge: true });
                    console.log(`Updated displayName for user ${userId}`);
                  } else {
                    console.log(`No displayName found in Auth for user ${userId}`);
                  }
                } catch (error) {
                  console.error(`Error fetching user from Auth: ${error}`);
                }
              }
            } else {
              console.log(`No user document found for userId: ${userId}, skipping...`);
            }
          });
        });
  
        return unsubscribe;
      };
  
      // Call the function to update roles and displayName when component mounts
      updateApplicantsRole();
  
      const unsubscribe = fetchFormData();
      return () => {
        unsubscribe();
      };
    }, [roleFilter, departmentFilter]);
  
  
    const fetchFormData = () => {
      let q = query(collection(db, "users"));
  
      if (searchTerm) {
        q = query(q, where("displayName", ">=", searchTerm), where("displayName", "<=", searchTerm + "\uf8ff"));
      }
  
      if (roleFilter) {
        q = query(q, where("role", "==", roleFilter));
      }
  
      if (departmentFilter) {
        q = query(q, where("department", "==", departmentFilter));
      }
  
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (!querySnapshot.empty) {
          const usersList = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setUsers(usersList);
        } else {
          setUsers([]);
        }
      });
  
      return unsubscribe;
    };
  
    const handleUpdate = async (id, key, value) => {
      await updateDoc(doc(db, "users", id), {
        [key]: value,
      });
    };

  const departments = [
    "N/A",
    "Pathogen Economy",
    "Mobility",
    "Infrastructure Innovations",
    "Industry 4.0+",
    "Aeronautics and Space",
    "Productivity Acceleration",
    "STI Support Services",
  ];

  const roles = ["Applicant", "Reviewer", "Administrator"];
  // Function to sort the table based on the selected column
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  // Fetch submitted applicants
  useEffect(() => {
    const fetchApplicants = async () => {
      const q = query(collection(db, "applications"), where("submitted", "==", true));

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const usersData = [];
        for (const docSnapshot of querySnapshot.docs) {
          const userId = docSnapshot.id;
          const userRef = doc(db, "users", userId);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            // Only add if both displayName and email exist
            if (userData.displayName && userData.email) {
              usersData.push({
                displayName: userData.displayName,
                email: userData.email,
              });
            }
          }
        }
        setUsers(usersData);
      });

      return unsubscribe;
    };

    fetchApplicants();
  }, []);

  // Handle Export to CSV
  const handleExportCSV = () => {
    if (users.length === 0) {
      console.error("No data available for export.");
      return;
    }

    const csvData = Papa.unparse(users);  // Convert JSON data to CSV format
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.setAttribute("href", url);
    link.setAttribute("download", "applicants.csv");
    link.click();
  };

  return (
    <>
      <AdminTopBar title="User Management" />

      <div className="container mx-auto p-6">

        {/* Filters and Search Bar */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
          {/* Filter by Role */}
          <select
            className="border border-gray-300 p-2 mr-4"
            value={roleFilter}
            onChange={(e) => setRoleFilter(e.target.value)}
          >
            <option value="">Filter by Role</option>
            {roles.map((role, i) => (
              <option key={i} value={role}>
                {role}
              </option>
            ))}
          </select>

          {/* Filter by Department */}
          <select
            className="border border-gray-300 p-2"
            value={departmentFilter}
            onChange={(e) => setDepartmentFilter(e.target.value)}
          >
            <option value="">Filter by Department</option>
            {departments.map((dept, i) => (
              <option key={i} value={dept}>
                {dept}
              </option>
            ))}
          </select>

          {/* Expandable Search Bar with Icon */}
          <div className="flex items-center border border-gray-300 p-2  transition-all focus-within:ring-2 focus-within:ring-blue-500 ml-auto">
            <SearchIcon className="w-5 h-5 text-gray-500 mr-2" />
            <input
              type="text"
              className="w-40 md:w-64 transition-all focus:outline-none"
              placeholder="Search by Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value.trim())}
            />
          </div>
                  {/* Export Button */}
        <button
          className="bg-blue-500 text-white px-4 py-2 mb-4 rounded hover:bg-blue-600"
          onClick={handleExportCSV}
        >
          Export Applicants to CSV
        </button>
        </div>

        {/* Users Table */}
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">No</th>
              <th
                className="py-2 px-4 border-b cursor-pointer"
                onClick={() => handleSort("displayName")}
              >
                Name{" "}
                {sortConfig.key === "displayName" &&
                  (sortConfig.direction === "ascending" ? "↑" : "↓")}
              </th>
              <th
                className="py-2 px-4 border-b cursor-pointer"
                onClick={() => handleSort("role")}
              >
                Role{" "}
                {sortConfig.key === "role" &&
                  (sortConfig.direction === "ascending" ? "↑" : "↓")}
              </th>
              <th
                className="py-2 px-4 border-b cursor-pointer"
                onClick={() => handleSort("department")}
              >
                Department (Bureau){" "}
                {sortConfig.key === "department" &&
                  (sortConfig.direction === "ascending" ? "↑" : "↓")}
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b">{index + 1}</td>
                <td className="py-2 px-4 border-b">{user.displayName}</td>

                {/* Editable Role field */}
                <td className="py-2 px-4 border-b">
                  <select
                    className="border border-gray-300 p-2 "
                    value={user.role }
                    onChange={(e) =>
                      handleUpdate(user.id, "role", e.target.value)
                    }
                  >
                    <option value="Applicant">Applicant</option>
                    <option value="Reviewer">Reviewer</option>
                    <option value="Administrator">Administrator</option>
                  </select>
                </td>

                {/* Editable Department field */}
                <td className="py-2 px-4 border-b">
                  <select
                    className="border border-gray-300 p-2 "
                    value={user.department}
                    onChange={(e) =>
                      handleUpdate(user.id, "department", e.target.value)
                    }
                  >
                    {departments.map((dept, i) => (
                      <option key={i} value={dept}>
                        {dept}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Users;
