import React, { useState, useEffect } from 'react';
import { db } from "../../Services/firebase-config";
import { collection, addDoc, onSnapshot, deleteDoc, doc, query, where } from "firebase/firestore";
import { useAuth } from "../../Contexts/AuthContext";
import AdminTopBar from "./AdminTopBar";

const Inbox = () => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [notificationsData, setNotificationsData] = useState([]);
  const { currentUser } = useAuth(); 
  const [sendTo, setSendTo] = useState("all"); 
  const [applicants, setApplicants] = useState([]); 

  useEffect(() => {
    const fetchNotifications = () => {
      const notificationsRef = collection(db, "notifications");
      const unsubscribe = onSnapshot(notificationsRef, (snapshot) => {
        const notifications = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        const groupedNotifications = notifications.reduce((acc, notification) => {
          const { message, read } = notification;
          if (!acc[message]) {
            acc[message] = { total: 0, reads: 0, ids: [] }; 
          }

          acc[message].total += 1;
          acc[message].ids.push(notification.id); 
          if (read) {
            acc[message].reads += 1;
          }

          return acc;
        }, {});

        const notificationsArray = Object.entries(groupedNotifications).map(
          ([message, stats]) => ({
            message,
            total: stats.total,
            reads: stats.reads,
            ids: stats.ids 
          })
        );

        setNotificationsData(notificationsArray);
      });

      return unsubscribe;
    };

    fetchNotifications();
  }, []);

  useEffect(() => {
    if (sendTo === "all") {
      const fetchSubmittedApplicants = () => {
        const q = query(collection(db, "users"), where("submitted", "==", true));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const submittedApplicants = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setApplicants(submittedApplicants);
        });
        return unsubscribe;
      };

      fetchSubmittedApplicants();
    }
  }, [sendTo]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (message.trim() === '') {
      setStatus('Please enter a valid message');
      return;
    }

    setLoading(true);

    try {
      if (sendTo === "all") {
        for (const applicant of applicants) {
          await addDoc(collection(db, "notifications"), {
            message: message,
            read: false,
            timestamp: new Date(),
            userId: applicant.id, 
          });
        }
        setStatus('Notification sent to all submitted applicants!');
      } else {
        await addDoc(collection(db, "notifications"), {
          message: message,
          read: false,
          timestamp: new Date(),
          userId: currentUser?.uid || "testUserId",
        });
        setStatus('Notification sent successfully!');
      }

      setMessage(''); 
    } catch (error) {
      setStatus('Failed to send notification. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteByMessage = async (notificationIds) => {
    try {
      for (const id of notificationIds) {
        await deleteDoc(doc(db, "notifications", id));
      }
      setStatus("Notification(s) deleted successfully");
    } catch (error) {
      console.error("Failed to delete notification(s): ", error);
      setStatus("Failed to delete notification(s)");
    }
  };

  return (
    <>
      <AdminTopBar title="Inbox" />
      <div className="container mx-auto p-6">
        <div className="bg-gray-100 p-4 mb-6 rounded-md shadow-md">
          <h2 className="text-xl font-bold mb-2">Notifications Overview</h2>
        </div>

        <h2 className="text-2xl font-bold mb-4">Send Notification to Applicants</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Notification Message:</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows="4"
              className="w-full border border-gray-300 p-2 rounded-md"
              placeholder="Write your message here..."
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Send To:</label>
            <select
              className="w-full border border-gray-300 p-2 rounded-md"
              value={sendTo}
              onChange={(e) => setSendTo(e.target.value)}
            >
              <option value="all">All Applicants (Submitted)</option>
              <option value="individual">Individual Applicants</option>
            </select>
          </div>

          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Send Notification'}
          </button>

          {status && <p className="text-sm mt-2 text-gray-600">{status}</p>}
        </form>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">Notifications Sent</h2>
          <table className="min-w-full bg-white border border-gray-300">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2 text-left">Message</th>
                <th className="px-4 py-2 text-left">Total Sent</th>
                <th className="px-4 py-2 text-left">Read</th>
                <th className="px-4 py-2 text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {notificationsData.length > 0 ? (
                notificationsData.map((notification, index) => (
                  <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
                    <td className="border px-4 py-2">{notification.message}</td>
                    <td className="border px-4 py-2">{notification.total}</td>
                    <td className="border px-4 py-2">{notification.reads}</td>
                    <td className="border px-4 py-2">
                      <button
                        onClick={() => handleDeleteByMessage(notification.ids)}
                        className="text-red-500 hover:underline mr-2"
                      >
                        Delete All
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center text-gray-500 py-4">
                    No notifications yet.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Inbox;
