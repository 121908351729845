import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AdminTopBar from "./AdminTopBar";
import Modal from "react-modal";
import { FaFileDownload, FaInfoCircle, FaClipboardCheck } from "react-icons/fa";
import {
  doc,
  collection,
  getDoc,
  setDoc,
  addDoc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../Services/firebase-config.js";
import { useApplicationForm } from "../../Contexts/ApplicationFormContext";
import { useAuth } from "../../Contexts/AuthContext";
import { getStorage, ref, getMetadata } from "firebase/storage";

const bureaus = [
  "Pathogen Economy",
  "Mobility",
  "Infrastructure Innovations",
  "Industry 4.0+",
  "Aeronautics and Space",
  "Productivity Acceleration",
  "STI Support Services",
];

Modal.setAppElement("#root");

const ApplicantReview = () => {
  const { currentUser } = useAuth();
  const { userData } = useApplicationForm();
  const [showProblemTooltip, setShowProblemTooltip] = useState(false);
  const [showSolutionTooltip, setShowSolutionTooltip] = useState(false);
  const [showNoveltyTooltip, setShowNoveltyTooltip] = useState(false);
  const [showObjectivesTooltip, setShowObjectivesTooltip] = useState(false);
  const [showMethodologyTooltip, setShowMethodologyTooltip] = useState(false);
  const [showOutputsTooltip, setShowOutputsTooltip] = useState(false);
  const [showRelevanceTooltip, setShowRelevanceTooltip] = useState(false);
  const location = useLocation();
  const submission = location.state?.submission;
  const [score, setScore] = useState(""); // State for innovation score
  const [comments, setComments] = useState(
    submission.reviews?.[currentUser.uid]?.comments
  ); // State for comments
  const [selectedBureau, setSelectedBureau] = useState(""); // State for selected bureau
  const [isGuidanceModalOpen, setIsGuidanceModalOpen] = useState(false); // State for guidance modal
  const [sectionScores, setSectionScores] = useState({
    problemDefinition: submission.reviews?.[currentUser.uid]?.problemDefinition,
    solution: submission.reviews?.[currentUser.uid]?.solution,
    novelty: submission.reviews?.[currentUser.uid]?.novelty,
    objectives: submission.reviews?.[currentUser.uid]?.objectives,
    methodology: submission.reviews?.[currentUser.uid]?.methodology,
    outputs: submission.reviews?.[currentUser.uid]?.outputs,
    relevance: submission.reviews?.[currentUser.uid]?.relevance,
  });
  const docRef = doc(db, "applications", submission.id);

  const toggleProblemTooltip = () => {
    setShowProblemTooltip(!showProblemTooltip);
  };

  const toggleSolutionTooltip = () => {
    setShowSolutionTooltip(!showSolutionTooltip);
  };

  const toggleNoveltyTooltip = () => {
    setShowNoveltyTooltip(!showNoveltyTooltip);
  };

  // Toggle function for Objectives Tooltip
  const toggleObjectivesTooltip = () => {
    setShowObjectivesTooltip((prevState) => !prevState);
  };

  // Toggle function for Methodology Tooltip
  const toggleMethodologyTooltip = () => {
    setShowMethodologyTooltip((prevState) => !prevState);
  };

  // Toggle function for Outputs/Deliverables Tooltip
  const toggleOutputsTooltip = () => {
    setShowOutputsTooltip((prevState) => !prevState);
  };

  // Toggle function for Relevance to Uganda's Development Priorities Tooltip
  const toggleRelevanceTooltip = () => {
    setShowRelevanceTooltip((prevState) => !prevState);
  };

  // Total Points Calculation
  const totalPoints =
    sectionScores.problemDefinition +
    sectionScores.solution +
    sectionScores.novelty +
    sectionScores.objectives +
    sectionScores.methodology +
    sectionScores.outputs +
    sectionScores.relevance;

  // Handle input change for score and comments
  const handleScoreChange = (e, field) => {
    setSectionScores((prevScores) => ({
      ...prevScores,
      [field]: parseInt(e.target.value) || 0,
    }));
  };

  const handleCommentsChange = (e) => setComments(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(docRef, {
        [`reviews.${currentUser.uid}`]: {
          score: totalPoints,
          comments: comments,
          reviewed: true,
          reviewName: currentUser.displayName,
          problemDefinition: sectionScores.problemDefinition,
          solution: sectionScores.solution,
          novelty: sectionScores.novelty,
          objectives: sectionScores.objectives,
          methodology: sectionScores.methodology,
          outputs: sectionScores.outputs,
          relevance: sectionScores.relevance,
          totalPoints: totalPoints,
          reviewedAt: new Date().toISOString(),
        },
      });
      alert("Scores updated successfully!");
    } catch (e) {
      console.error("Error updating document: ", e);
      alert("Error updating scores.");
    }
  };

  const handleForward = () => {
    // Logic to forward to the selected bureau
    if (selectedBureau) {
      console.log("Forwarded to:", selectedBureau);
      alert(`Application forwarded to ${selectedBureau}`);
    } else {
      alert("Please select a bureau to forward the application.");
    }
  };

  const toggleGuidanceModal = () => {
    setIsGuidanceModalOpen(!isGuidanceModalOpen);
  };

  const toSentenceCase = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const navigate = useNavigate(); // Initialize navigation hook
  // Inside ApplicantReview component
  // Function to handle the file viewing
  const handleViewFile = async (fileUrl) => {
    if (!fileUrl) {
      alert("No file available to view.");
      return;
    }

    const storage = getStorage();

    // Extract the storage reference from the URL
    const fileRef = ref(storage, fileUrl);

    try {
      // Get file metadata including the MIME type
      const metadata = await getMetadata(fileRef);
      const mimeType = metadata.contentType;

      navigate("/file-viewer", {
        state: { fileUrl, mimeType, submissionId: submission.id },
      });
    } catch (error) {
      console.error("Error retrieving file metadata:", error);
      alert("Unable to load file metadata.");
    }
  };

  return (
    <>
      <AdminTopBar title="Review Applicant" />

      <div className="container mx-auto p-6 space-y-6 max-w-3xl">
        {/* Venture Title */}
        <div className="bg-white p-6  border border-gray-300">
          <h2 className="text-xl font-bold mb-4">Venture Title</h2>
          {/* Convert the title to sentence case */}
          <p className="text-gray-700">{toSentenceCase(submission.title)}</p>
        </div>

        {/* Innovation Brief */}
        <div className="bg-white p-6  border border-gray-300">
          <h2 className="text-xl font-bold mb-4">Innovation Brief</h2>
          <p className="text-gray-700">
            {toSentenceCase(submission.description)}
          </p>
        </div>

        {/* Attachments */}
        <div className="bg-white p-6  border border-gray-300">
          <h2 className="text-xl font-bold mb-4">Attachments</h2>
          <ul className="space-y-4">
            <li>
              <button
                className="text-blue-500 hover:underline"
                onClick={() => handleViewFile(submission.applicationFile)}
              >
                View Application Proposal
              </button>
            </li>
            <li>
              <a
                href={submission.budgetFile}
                download
                className="text-blue-500 hover:underline"
              >
                View Budget
              </a>

              {/* <button
                className="text-blue-500 hover:underline"
                onClick={() => handleViewFile(submission.budgetFile)}
              >
                View Budget
              </button> */}
            </li>

            <li>
              <>
                <a
                  href={submission.supportMaterials}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  View Support Materials
                </a>
              </>
            </li>

            <li>
              <button
                className="text-blue-500 hover:underline"
                onClick={() => handleViewFile(submission.teamCVs)}
              >
                View Team Cvs
              </button>
            </li>
            <li>
              <button
                className="text-blue-500 hover:underline"
                onClick={() => handleViewFile(submission.nationalID)}
              >
                View National ID
              </button>
            </li>
            <li>
              <button
                className="text-blue-500 hover:underline"
                onClick={() =>
                  handleViewFile(submission.registrationCertificate)
                }
              >
                View Registration Certificate
              </button>
            </li>
            <li>
              <button
                className="text-blue-500 hover:underline"
                onClick={() => handleViewFile(submission.resolutionLetter)}
              >
                View Reference Letter
              </button>
            </li>
          </ul>
          {/* Add other attachments similarly */}

          {/* <ul className="space-y-4">
            {[
              { name: "Application Proposal", url: submission.applicationFile },
              { name: "Budget", url: submission.budgetFile },
              { name: "Team CVs", url: submission.teamCVs },
              { name: "National ID", url: submission.nationalID },
              {
                name: "Registration Certificate",
                url: submission.registrationCertificate,
              },
              { name: "Resolution Letter", url: submission.resolutionLetter },
            ].map((attachment, index) => (
              <li key={index} className="flex items-center space-x-4">
                <FaFileDownload className="text-blue-600 w-6 h-6" />
                <a
                  href={attachment.url}
                  download
                  className="text-blue-500 hover:underline"
                >
                  {attachment.name}
                </a>
              </li>
            ))}
          </ul> */}
        </div>

        {/* Review Form */}
        <form onSubmit={handleSubmit}>
          <div className="bg-white p-6 border border-gray-300">
            <div className="flex items-center mb-4">
              <h2 className="text-xl font-bold text-custom-blue-1 mr-2">
                Final Review
              </h2>
              <FaClipboardCheck className="text-custom-blue-1 h-6 w-6" />
            </div>
            <hr className="border-t border-gray-300 mb-6 opacity-50" />

            {/* Collapsible Section for Review Instructions */}
            <details className="mb-6">
              <summary className="font-bold text-gray-700 cursor-pointer flex items-center">
                <FaInfoCircle className="text-custom-blue-1 h-5 w-5 mr-2" />
                How to Review!
              </summary>
              <div className="mt-2 text-gray-600 text-md bg-custom-blue-1 bg-opacity-10 p-4">
                <p>1. Click the submitted application and attachments.</p>
                <p>2. Read the documents thoroughly.</p>
                <p>3. Award points based on the merit of the submission.</p>
                <p>4. Use the "i" icons for detailed guidance on scoring.</p>
              </div>
            </details>
            <hr className="border-t border-gray-300 mb-6 opacity-50" />
            {/* Problem Definition */}
            <div className="flex justify-between items-center mb-6">
              <div>
                <label className="block text-gray-700 font-bold text-lg">
                  1. Problem Definition (Out of 15)
                </label>
                <p className="text-gray-500">
                  Is the problem big enough? Has the proposal explained the
                  struggle? Is the problem significant?
                </p>
              </div>
              {/* Info Icon with Tooltip */}
              <div className="relative">
                <FaInfoCircle
                  className="text-custom-blue-1 cursor-pointer"
                  onMouseEnter={toggleProblemTooltip}
                  onMouseLeave={toggleProblemTooltip}
                />
                {showProblemTooltip && (
                  <div className="absolute right-0 mt-2 w-64 p-3 bg-white border border-gray-300 rounded shadow-lg text-sm text-gray-700 z-10">
                    <ol className="list-decimal pl-4">
                      <li>
                        How important is the problem? Is it significant enough
                        to elicit attention? (5 points)
                      </li>
                      <li>
                        Does the proposal clearly explain the struggles caused
                        by the problem? (5 points)
                      </li>
                      <li>
                        Does the problem highlight a gap in the market or a need
                        for innovation? (5 points)
                      </li>
                    </ol>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4">
              <input
                type="number"
                value={sectionScores.problemDefinition}
                onChange={(e) => {
                  const value = Math.max(0, Math.min(15, e.target.value)); // Ensure value is between 0 and 15
                  handleScoreChange({ target: { value } }, "problemDefinition");
                }}
                className="w-full border border-gray-300 p-2"
                min="0"
                max="15"
              />
            </div>

            {/* Solution */}
            <div className="flex justify-between items-center mb-6 mt-10">
              <div>
                <label className="block text-gray-700 font-bold text-lg">
                  2. Solution (Out of 15)
                </label>
                <p className="text-gray-500">
                  Does the innovation or solution address the problem?
                </p>
              </div>
              {/* Info Icon with Tooltip */}
              <div className="relative">
                <FaInfoCircle
                  className="text-custom-blue-1 cursor-pointer"
                  onMouseEnter={toggleSolutionTooltip}
                  onMouseLeave={toggleSolutionTooltip}
                />
                {showSolutionTooltip && (
                  <div className="absolute right-0 mt-2 w-64 p-3 bg-white border border-gray-300 rounded shadow-lg text-sm text-gray-700 z-10">
                    <ol className="list-decimal pl-4">
                      <li>
                        Is the solution practical and achievable within a
                        realistic resource envelope? Will the solution have
                        long-term benefits and be sustainable over time? (5
                        points)
                      </li>
                      <li>
                        Will the proposed solution effectively address the
                        problem? (5 points)
                      </li>
                      <li>
                        Can the solution be scaled up to reach a larger audience
                        or market? (5 points)
                      </li>
                    </ol>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4">
              <input
                type="number"
                value={sectionScores.solution}
                onChange={(e) => {
                  const value = Math.max(0, Math.min(15, e.target.value)); // Ensure value is between 0 and 15
                  handleScoreChange({ target: { value } }, "solution");
                }}
                className="w-full border border-gray-300 p-2"
                min="0"
                max="15"
              />
            </div>

            {/* Novelty/Originality */}
            <div className="flex justify-between items-center mb-6 mt-10">
              <div>
                <label className="block text-gray-700 font-bold text-lg">
                  3. Novelty/Originality (Out of 10)
                </label>
                <p className="text-gray-500">
                  Is the product or service novel?
                </p>
              </div>
              {/* Info Icon with Tooltip */}
              <div className="relative">
                <FaInfoCircle
                  className="text-custom-blue-1 cursor-pointer"
                  onMouseEnter={toggleNoveltyTooltip}
                  onMouseLeave={toggleNoveltyTooltip}
                />
                {showNoveltyTooltip && (
                  <div className="absolute right-0 mt-2 w-64 p-3 bg-white border border-gray-300 rounded shadow-lg text-sm text-gray-700 z-10">
                    <ol className="list-decimal pl-4">
                      <li>
                        Could this innovation disrupt the current market or
                        system? (4 points)
                      </li>
                      <li>
                        Does the solution stand out from the existing products
                        or services through the use of new or creative ways to
                        tackle the issue (6 points)
                      </li>
                    </ol>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4">
              <input
                type="number"
                value={sectionScores.novelty}
                onChange={(e) => {
                  const value = Math.max(0, Math.min(10, e.target.value)); // Limit value between 0 and 10
                  handleScoreChange({ target: { value } }, "novelty");
                }}
                className="w-full border border-gray-300 p-2"
                min="0"
                max="10"
              />
            </div>

            {/* Objectives */}
            <div className="flex justify-between items-center mb-6 mt-10">
              <div>
                <label className="block text-gray-700 font-bold text-lg">
                  4. Objectives (Out of 5)
                </label>

                <p className="text-gray-500">
                  Are the objectives SMART? (The objectives should align with
                  the phase of the venture and the problem)
                </p>
              </div>
              {/* Info Icon with Tooltip */}
              <div className="relative">
                <FaInfoCircle
                  className="text-custom-blue-1 cursor-pointer"
                  onMouseEnter={toggleObjectivesTooltip}
                  onMouseLeave={toggleObjectivesTooltip}
                />
                {showObjectivesTooltip && (
                  <div className="absolute right-0 mt-2 w-64 p-3 bg-white border border-gray-300 rounded shadow-lg text-sm text-gray-700 z-10">
                    <ol className="list-decimal pl-4">
                      <li>
                        Are the objectives SMART? (The objectives should align
                        with the phase of the venture and the problem)? (2
                        points)
                      </li>
                      <li>
                        Are the objectives relevant towards creating a solution
                        for the identified problem (3 points)
                      </li>
                    </ol>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4">
              <input
                type="number"
                value={sectionScores.objectives}
                onChange={(e) => {
                  const value = Math.max(0, Math.min(5, e.target.value)); // Limit value between 0 and 5
                  handleScoreChange({ target: { value } }, "objectives");
                }}
                className="w-full border border-gray-300 p-2"
                min="0"
                max="5"
              />
            </div>

            {/* Methodology */}
            <div className="flex justify-between items-center mb-6 mt-10">
              <div>
                <label className="block text-gray-700 font-bold text-lg">
                  5. Methodology (Out of 30)
                </label>
                <p className="text-gray-500">
                  Is there a detailed chronological approach to realizing the
                  solution? Is it technically plausible?
                </p>
              </div>
              {/* Info Icon with Tooltip */}
              <div className="relative">
                <FaInfoCircle
                  className="text-custom-blue-1 cursor-pointer"
                  onMouseEnter={toggleMethodologyTooltip}
                  onMouseLeave={toggleMethodologyTooltip}
                />
                {showMethodologyTooltip && (
                  <div className="absolute right-0 mt-2 w-64 p-3 bg-white border border-gray-300 rounded shadow-lg text-sm text-gray-700 z-10">
                    <ol className="list-decimal pl-4">
                      <li>
                        Is the methodology appropriate for realising the set
                        objectives? (5 points)
                      </li>
                      <li>
                        Is the methodology aligned to the objectives? (5 points)
                      </li>
                      <li>
                        Does the methodology offer an innovative or unique
                        approach? Does it have scientific merit?(5 points)
                      </li>
                      <li>
                        Are potential risks identified, and is there a plan to
                        mitigate them? (5 points)
                      </li>
                      <li>
                        Does the proposal include the team members’ expertise
                        and skillsets and compelling evidence of their ability
                        to implement the proposal? (5 points)
                      </li>
                      <li>
                        Does the budget align to the venture activities? (5
                        points)
                      </li>
                    </ol>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4">
              <input
                type="number"
                value={sectionScores.methodology}
                onChange={(e) => {
                  const value = Math.max(0, Math.min(30, e.target.value)); // Limit value between 0 and 30
                  handleScoreChange({ target: { value } }, "methodology");
                }}
                className="w-full border border-gray-300 p-2"
                min="0"
                max="30"
              />
            </div>

            {/* Outputs/Deliverables */}
            <div className="flex justify-between items-center mb-6 mt-10">
              <div>
                <label className="block text-gray-700 font-bold text-lg">
                  6. Outputs/Deliverables (Out of 15)
                </label>
                <p className="text-gray-500">
                  Do the deliverables align with the objectives and solution?
                </p>
              </div>
              {/* Info Icon with Tooltip */}
              <div className="relative">
                <FaInfoCircle
                  className="text-custom-blue-1 cursor-pointer"
                  onMouseEnter={toggleOutputsTooltip}
                  onMouseLeave={toggleOutputsTooltip}
                />
                {showOutputsTooltip && (
                  <div className="absolute right-0 mt-2 w-64 p-3 bg-white border border-gray-300 rounded shadow-lg text-sm text-gray-700 z-10">
                    <ol className="list-decimal pl-4">
                      <li>
                        Are the expected outputs and deliverables clearly
                        stated? (3 points)
                      </li>
                      <li>
                        Are the deliverables measurable and quantifiable? (3
                        points)
                      </li>
                      <li>
                        Is there a reasonable timeline for achieving these
                        deliverables? (3 points)
                      </li>
                      <li>
                        Do the deliverables align with the project's overall
                        objectives? (3 points)
                      </li>
                      <li>
                        Are the outputs realistic given the project scope and
                        resources? (3 points)
                      </li>
                    </ol>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4">
              <input
                type="number"
                value={sectionScores.outputs}
                onChange={(e) => {
                  const value = Math.max(0, Math.min(15, e.target.value)); // Limit value between 0 and 15
                  handleScoreChange({ target: { value } }, "outputs");
                }}
                className="w-full border border-gray-300 p-2"
                min="0"
                max="15"
              />
            </div>

            {/* Relevance to Uganda’s Development Priorities */}
            <div className="flex justify-between items-center mb-6 mt-10">
              <div>
                <label className="block text-gray-700 font-bold text-lg">
                  7. Relevance to Uganda’s Development Priorities (Out of 10)
                </label>
                <p className="text-gray-500">
                  Is the venture relevant to national development priorities
                  (NDP III/ Vision 2040, NRM manifesto)?
                </p>
              </div>
              {/* Info Icon with Tooltip */}
              <div className="relative">
                <FaInfoCircle
                  className="text-custom-blue-1 cursor-pointer"
                  onMouseEnter={toggleRelevanceTooltip}
                  onMouseLeave={toggleRelevanceTooltip}
                />
                {showRelevanceTooltip && (
                  <div className="absolute right-0 mt-2 w-64 p-3 bg-white border border-gray-300 rounded shadow-lg text-sm text-gray-700 z-10">
                    <ol className="list-decimal pl-4">
                      <li>
                        Does the solution align with Uganda’s key development
                        priorities? (5 points)
                      </li>
                      <li>
                        Will the innovation positively impact Uganda’s economic
                        development towards realization of the qualitative leap?
                        (5 points)
                      </li>
                    </ol>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4">
              <input
                type="number"
                value={sectionScores.relevance}
                onChange={(e) => {
                  const value = Math.max(0, Math.min(10, e.target.value)); // Limit value between 0 and 10
                  handleScoreChange({ target: { value } }, "relevance");
                }}
                className="w-full border border-gray-300 p-2"
                min="0"
                max="10"
              />
            </div>

            {/* Total Points Display */}
            <div className="mb-6 mt-10">
              <label className="block text-gray-700 font-bold mb-2">
                Total Points: {totalPoints} / 100
              </label>
            </div>

            {/* Comments */}
            <div className="mb-6">
              <label className="block text-gray-700 font-bold mb-2">
                Comments
              </label>
              <textarea
                value={comments}
                onChange={handleCommentsChange}
                className="w-full border border-gray-300 p-2 "
                rows="4"
                required
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-between">
              <button
                type="submit"
                className="bg-custom-green-1 text-white px-4 py-2  hover:bg-green-600"
              >
                Save Review
              </button>

              {/* Forward to Bureau Section */}
              {/* <div className="flex items-center space-x-4">
                <select
                  value={selectedBureau}
                  onChange={(e) => setSelectedBureau(e.target.value)}
                  className="border border-gray-300 p-2 "
                >
                  <option value="">Forward to Bureau</option>
                  {bureaus.map((bureau, index) => (
                    <option key={index} value={bureau}>
                      {bureau}
                    </option>
                  ))}
                </select>
                <button
                  type="button"
                  onClick={handleForward}
                  className="bg-blue-500 text-white px-4 py-2  hover:bg-blue-600"
                >
                  Forward
                </button>
              </div> */}
            </div>
          </div>
        </form>
      </div>

      {/* Guidance Modal */}
    </>
  );
};

export default ApplicantReview;
