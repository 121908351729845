import React, { createContext, useState, useContext, useEffect } from "react";
import { getFirestore, doc, setDoc, onSnapshot } from "firebase/firestore";
import { db } from "../Services/firebase-config.js";
import { useAuth } from "../Contexts/AuthContext";

const ApplicationFormContext = createContext();

export const useApplicationForm = () => useContext(ApplicationFormContext);

export const ApplicationFormProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [submissions, setSubmissions] = useState(null);

  useEffect(() => {
    const updateLastAccessed = async () => {
      if (currentUser) {
        const userRef = doc(db, "users", currentUser.uid);
        await setDoc(userRef, { lastAccessed: new Date(), displayName: currentUser.displayName }, { merge: true });
      }
    };

    const subscribeToUserData = () => {
      if (currentUser) {
        const userRef = doc(db, "users", currentUser.uid);
        return onSnapshot(userRef, (doc) => {
          if (doc.exists()) {
            setUserData(doc.data());
          }
        });
      }
    };

    updateLastAccessed();
    const unsubscribe = subscribeToUserData();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser]);

  return (
    <ApplicationFormContext.Provider
      value={{ formData, setFormData, userData, submissions, setSubmissions }}
    >
      {children}
    </ApplicationFormContext.Provider>
  );
};
