import React, { useState, useEffect } from "react";
import {
  sendEmailVerification,
  signOut,
  onIdTokenChanged,
  reload,
} from "firebase/auth";
import { useAuth } from "../../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const EmailVerification = () => {
  const [isVerificationSent, setIsVerificationSent] = useState(false);
  const [error, setError] = useState("");
  const { auth, currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        await reload(user);
        if (user.emailVerified) {
          navigate("/");
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleResendVerification = async () => {
    if (currentUser) {
      try {
        // Send verification email to the current user
        await sendEmailVerification(currentUser);
        setIsVerificationSent(true);
      } catch (error) {
        console.error("Error sending verification email: ", error);
        setError("Failed to send verification email. Please try again.");
      }
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out from Firebase
      navigate("/"); // Redirect to the homepage after sign out
    } catch (error) {
      console.error("Logout failed", error);
      // Handle errors here, such as displaying a notification
    }
  };

  return (
    <div
      className="h-screen bg-cover bg-center flex flex-col justify-center items-center"
      style={{ backgroundImage: `url('/Images/background.jpg')` }}
    >
      <div className="max-w-md w-full bg-custom-blue-1 p-8 border shadow">
        <div className="flex justify-center mb-8">
          <img className="w-20" src={"/Images/STIlogo.svg"} alt="STI Logo" />
        </div>
        <h2 className="text-2xl font-normal mb-2 text-white text-center">
          Verify Your Email
        </h2>
        <p className="text-white text-center mb-4">
          We have sent a verification email to <br />
          <b>{currentUser?.email ?? "Anonymous"}</b>. <br />
          <br />
          Please check your inbox and click the verification link to proceed.
        </p>

        <div className="mt-6 flex flex-col justify-center items-center">
          <button
            onClick={handleResendVerification}
            className="bg-custom-green-1 text-custom-blue-1 py-2 px-4 border border-transparent hover:text-white"
          >
            Resend Verification Email
          </button>

          {isVerificationSent && (
            <div className="mt-4 p-4 bg-green-100 text-green-800 rounded">
              A verification email has been resent. Please check your inbox.
            </div>
          )}

          {error && (
            <div className="mt-4 p-4 bg-red-100 text-red-800 rounded">
              {error}
            </div>
          )}

          <p
            onClick={handleLogout}
            className="mt-5 text-white hover:underline cursor-pointer"
          >
            Switch Account
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
