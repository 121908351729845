import React, { useState, useEffect, useRef } from "react";
import AdminTopBar from "./AdminTopBar";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, where, query } from "firebase/firestore";
import { db } from "../../Services/firebase-config.js";
import {
  DocumentTextIcon,
  CheckCircleIcon,
  DotsVerticalIcon,
} from "@heroicons/react/outline";

const AdminDashboard = () => {
  const [totalSubmissions, setTotalSubmissions] = useState(0);
  const [totalReviewed, setTotalReviewed] = useState(0);
  const [departments, setDepartments] = useState([
    { name: "Pathogen Economy", totalReceived: 0, totalReviewed: 0 },
    { name: "Mobility", totalReceived: 0, totalReviewed: 0 },
    { name: "Infrastructure Innovations", totalReceived: 0, totalReviewed: 0 },
    { name: "Industry 4.0+", totalReceived: 0, totalReviewed: 0 },
    { name: "Aeronautics and Space", totalReceived: 0, totalReviewed: 0 },
    { name: "Productivity Acceleration", totalReceived: 0, totalReviewed: 0 },
    { name: "STI Support Services", totalReceived: 0, totalReviewed: 0 },
  ]);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const navigate = useNavigate(); // Use for navigating to department-specific submissions
  const dropdownRef = useRef(null);

  // Function to close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(null); // Close the dropdown
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    countDepartmentsInValueChain();
  }, []);

  // Function to handle "View Submissions" click for each department
  const handleViewSubmissions = (department) => {
    // Navigate to the submissions page (pass the department name or ID as a parameter)
    navigate(`/submissions`);
  };

  const countDepartmentsInValueChain = async () => {
    try {
      const applicationsCollection = collection(db, "applications");
      
      // Query all submitted applications
      const q = query(applicationsCollection, where("submitted", "==", true));
  
      const querySnapshot = await getDocs(q);
      const totalSubmissionsCount = querySnapshot.size;
      
      // Initialize counts for departments
      const departmentCounts = departments.map((department) => ({
        ...department,
        totalReceived: 0,
        totalReviewed: 0,
      }));
  
      // Iterate through each document
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const reviews = data.reviews || {}; // Access reviews map
        const isReviewed = Object.values(reviews).some((review) => review.reviewed === true);
        const departmentName = data.valueChain; // Assume 'valueChain' is the department field
  
        // Find the corresponding department in the array
        const departmentIndex = departmentCounts.findIndex(
          (dept) => dept.name === departmentName
        );
  
        if (departmentIndex !== -1) {
          // Increment the totalReceived count for the department
          departmentCounts[departmentIndex].totalReceived++;
  
          // If the submission is reviewed, increment the totalReviewed count
          if (isReviewed) {
            departmentCounts[departmentIndex].totalReviewed++;
          }
        }
      });
  
      // Update the total reviewed and total submissions
      const totalReviewedCount = departmentCounts.reduce(
        (acc, dept) => acc + dept.totalReviewed,
        0
      );
  
      setTotalReviewed(totalReviewedCount);
      setTotalSubmissions(totalSubmissionsCount);
      setDepartments(departmentCounts);
  
    } catch (error) {
      console.error("Error counting submissions in value chain:", error);
    }
  };
  

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const handleViewReport = (department) => {
    console.log("Viewing report for", department.name);
  };

  return (
    <>
      <AdminTopBar title="Admin Dashboard" />

      <div className="container mx-auto p-6 space-y-6">
        {/* Summary Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-blue-100 text-blue-800 p-6  flex items-center justify-between">
            <div>
              <h3 className="text-2xl font-bold">Total Submissions</h3>
              <p className="text-lg mt-2">{totalSubmissions}</p>
            </div>
            {/* Replacing SVG with Heroicon */}
            <DocumentTextIcon className="h-12 w-12 text-blue-600" />
          </div>

          <div className="bg-green-100 text-green-800 p-6  flex items-center justify-between">
            <div>
              <h3 className="text-2xl font-bold">Reviewed Submissions</h3>
              <p className="text-lg mt-2">{totalReviewed}</p>
            </div>
            {/* Replacing SVG with Heroicon */}
            <CheckCircleIcon className="h-12 w-12 text-green-600" />
          </div>
        </div>

        {/* Submissions per Department Table */}
        <div className="bg-white p-6 ">
          <h2 className="text-xl font-bold mb-4">Submissions per Department</h2>
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b text-left">Department</th>
                <th className="py-2 px-4 border-b text-left">Total</th>
                <th className="py-2 px-4 border-b text-left">Reviewed</th>
                <th className="py-2 px-4 border-b text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {departments.map((department, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 border-b">{department.name}</td>
                  <td className="py-2 px-4 border-b">
                    {department.totalReceived}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {department.totalReviewed}
                  </td>
                  <td className="py-2 px-4 border-b">
                    <div
                      className="relative inline-block text-left"
                      ref={dropdownRef}
                    >
                      <DotsVerticalIcon
                        className="w-5 h-5 cursor-pointer"
                        onClick={() => toggleDropdown(index)}
                      />
                      {dropdownOpen === index && (
                        <div className="absolute right-0 mt-2 w-32 bg-white shadow-lg border border-gray-300 z-10">
                          <button
                            className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
                            onClick={() => handleViewSubmissions(department)}
                          >
                            Submissions
                          </button>
                          <button
                            className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
                            onClick={() => handleViewReport(department)}
                          >
                            View Report
                          </button>
                        </div>
                      )}
                    </div>

                    {/* <button
                      onClick={() => handleViewSubmissions(department)}
                      className="bg-custom-blue-1 text-white px-3 py-1  hover:bg-blue-600 transition"
                    >
                      View Submissions
                    </button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
