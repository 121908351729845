import React, { useState, useEffect } from "react";
import AdminTopBar from "./AdminTopBar";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../Services/firebase-config.js";
import { FaRegTimesCircle } from "react-icons/fa";
import { useAuth } from "../../Contexts/AuthContext"; // Step 1: Import useAuth to get currentUser

const ApplicationForm = () => {
  const { currentUser } = useAuth(); // Step 2: Access the current user
  const [formData, setFormData] = useState(null);
  const [loadingFormData, setLoadingFormData] = useState(false);
  const [error, setError] = useState(null);
  const [comments, setComments] = useState([]); // State to hold comments
  const [isExpanded, setIsExpanded] = useState(false); // State to manage "View More" for rejection message
  const [isSuccessExpanded, setIsSuccessExpanded] = useState(false); // State for success message

  useEffect(() => {
    // Step 3: Define userId conditionally for debugging
    const userId =
      currentUser?.uid;

    // "2nVdaDe8tlPVtrB1wfEAKRO9nc72";

    if (userId) {
      // Only fetch data if the user is logged in or the test userId is available
      fetchFormData(userId); // Use userId or currentUser's ID
    }
  }, [currentUser]);

  // Fetch Application Data
  const fetchFormData = async (userId) => {
    setLoadingFormData(true);
    try {
      const docRef = doc(db, "applications", userId); // Use userId for testing or currentUser.uid
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setFormData(data);

        // Extract comments from the reviews object if it exists
        const reviews = data.reviews || {};
        const extractedComments = Object.values(reviews)
          .map((review) => review.comments)
          .filter((comment) => comment); // Filter out empty comments

        setComments(extractedComments);
      } else {
        setError("Application not found.");
      }
    } catch (error) {
      console.error("Error fetching form data: ", error);
      setError("Failed to load application data.");
    }
    setLoadingFormData(false);
  };

  // If loading or error, show appropriate messages
  if (loadingFormData) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="bg-white p-8 shadow-lg border border-gray-300 text-center max-w-md">
          <FaRegTimesCircle className="text-red-600 w-16 h-16 mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-red-600 mb-4">
            Error Loading Application
          </h2>
          <p className="text-gray-700 mb-4">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <AdminTopBar title="Reviewer Comments" />

      {/* Notification for Non-submitted Applications */}
      {!formData?.submitted && (
        <div className="w-full mx-auto relative mt-8 p-8 max-w-3xl">
          <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative mb-8">
            <strong className="font-bold">Notice:</strong>
            <span className="block sm:inline">
              No valid or complete submission was found for this application. Please ensure all required information is provided and submit your application before the deadline in the next call.
            </span>


          </div>
        </div>
      )}

      {/* Display Evaluation Status and Comments if the Application is Submitted */}
      {formData?.submitted && (
        <div className="w-full mx-auto relative mt-8 p-8 max-w-3xl">
          {/* Evaluation Status for Approved Applications */}
          {formData?.evaluationPhase1Status === "approved" && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-8">
              <strong className="font-bold">Success!</strong>
              <span className="block sm:inline">
                You have successfully completed Phase 1 of 3 evaluation phases.
              </span>
              <button
                className="text-blue-500 ml-2 hover:underline"
                onClick={() => setIsSuccessExpanded(!isSuccessExpanded)}
              >
                {isSuccessExpanded ? "View Less" : "View More"}
              </button>
              {isSuccessExpanded && (
                <div className="mt-4">
                  <p className="text-gray-700">
                    Dear Applicant,
                    <br />
                    <br />
                    On behalf of the Science, Technology and Innovation Secretariat at the Office of the President (STI-OP), we wish to thank you for your interest in innovation, exemplified by your submission of a proposal to the Funding Call issued by STI-OP.
                    <br />
                    <br />
                    We have completed the first level of review, and the reviewers have given feedback on your proposal, which is available on the application portal. We are glad to inform you that your application has been considered for the next phase of evaluation. Please note that proceeding to this phase is not a guarantee of funding from STI-OP.
                    <br />
                    <br />
                    Our team will reach out to you with information regarding the next steps as may be required.
                    <br />
                    <br />
                    Contact us at <a href="mailto:info@sti.go.ug" className="text-blue-500 hover:underline">info@sti.go.ug</a>.
                    <br />
                    <br />
                    Regards,
                    <br />
                    The STI-OP Team
                  </p>
                </div>
              )}
            </div>
          )}

          {/* Evaluation Status for Rejected Applications */}
          {formData?.evaluationPhase1Status === "rejected" && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-8">
              <strong className="font-bold">Regret:</strong>
              <span className="block sm:inline">
                We regret to inform you that your application was not successful through the evaluation. We encourage you to keep pushing and try again through the same call or even apply for the Science Week event as well.
              </span>
              <button
                className="text-blue-500 ml-2 hover:underline"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? "View Less" : "View More"}
              </button>
              {isExpanded && (
                <div className="mt-4">
                  <p className="text-gray-700">
                    Dear Applicant,
                    <br />
                    <br />
                    On behalf of the Science, Technology and Innovation Secretariat at the Office of the President (STI-OP), we wish to thank you for your interest in innovation, exemplified by your submission of a proposal to the Funding Call issued by STI-OP.
                    <br />
                    <br />
                    We have completed the first level of review: The reviewers have given feedback on your proposal, and the reviews are available on the application portal. Kindly examine them so that you can improve your idea for future submission. To this end, your grant application has not been considered for financial support in this round.
                    <br />
                    <br />
                    Selecting grant recipients from an outstanding and varied group of applicants is always a challenging task, especially considering the variety of excellent proposals submitted for consideration. Therefore, this action is not a reflection of the overall quality of your application. We believe that with more thought and input, your proposal will be more competitive in the future.
                    <br />
                    <br />
                    We appreciate the effort and time that went into the development of your proposal. The STI-OP Team is available to provide assistance towards improving your proposal, outside the application cycle.
                    <br />
                    <br />
                    Contact us at <a href="mailto:info@sti.go.ug" className="text-blue-500 hover:underline">info@sti.go.ug</a>.
                    <br />
                    <br />
                    Regards,
                    <br />
                    The STI-OP Team
                  </p>
                </div>
              )}
            </div>
          )}

          {/* Display Comments */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-4">Comments from Reviewers</h2>
            {comments.length > 0 ? (
              <ul className="space-y-4">
                {comments.map((comment, index) => (
                  <li key={index} className="bg-gray-100 p-4 rounded-md shadow-sm">
                    <span className="font-semibold text-gray-800">
                      Comment {index + 1}:
                    </span>
                    <p className="text-gray-700 mt-2">{comment}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-600">No comments available from reviewers.</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ApplicationForm;
